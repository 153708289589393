import { useEffect, useRef } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../app/appHooks";
import {
  failedFirebaseReports,
  loadCommunityUnits as loadReportCommunityUnits,
} from "./reportsSlice";
import { FloatingNotificationPanel } from "../common/FloatingNotificationPanel";

import { UnitList } from "./UnitsList";
import { ReportsViewer } from "./ReportsViewer";
import { minToolbarHeight } from "../app/App";
import { loadUnitsFromFirebase } from "../../helpers/utils";

const useStyles = makeStyles<Theme, Boolean>((theme: Theme) =>
  createStyles({
    reportsContainer: {
      display: "flex",
      height: (showBanner) =>
        `calc(100vh - ${minToolbarHeight(showBanner, theme)})`,
    },
    unitListContainer: {
      display: "flex",
      flex: "0 0 300px",
      backgroundColor: theme.palette.light.light,
    },
    reportsViewerContainer: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
      overflowY: "hidden",
      overflowX: "hidden",
      "@media (max-height: 830px)": {
        overflowY: "auto",
      },
      [theme.breakpoints.down("md")]: {
        overflowY: "auto",
      },
    },
  })
);

export default function Reports() {
  const showBanner = useAppSelector((state) => state.headerState.showBanner);

  /* ---- Hooks ---- */
  const classes = useStyles(showBanner);
  const dispatch = useDispatch();

  /* ---- Selectors ---- */
  const firebaseAuth = useAppSelector((state) => state.loginState.firebaseAuth);
  const communityID = useAppSelector(
    (state) => state.headerState.selectedCommunity?.id
  );
  const userUnits = useAppSelector((state) => state.headerState.units);
  const user = useAppSelector((state) => state.headerState.user);

  /* ---- Refs ---- */
  const containerRef = useRef<HTMLDivElement | null>(null);

  /* ---- Effects ---- */
  useEffect(() => {
    return loadUnitsFromFirebase(
      firebaseAuth,
      user,
      communityID,
      dispatch,
      loadReportCommunityUnits,
      failedFirebaseReports
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityID, firebaseAuth, user, user?.roles, userUnits]);

  return (
    <div className={classes.reportsContainer}>
      <div className={classes.unitListContainer}>
        <UnitList />
      </div>
      <div
        className={classes.reportsViewerContainer}
        ref={containerRef}
        id="viewer-container"
      >
        <ReportsViewer />
      </div>
      <FloatingNotificationPanel />
    </div>
  );
}
