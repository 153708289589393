import { IEvent } from "../services/dashboard.services";
import { shadeColor } from "./colorUtils";
import Icons, {
  ContactIcons,
  DashboardIcons,
  NotificationIcons,
} from "./iconImports";
import store from "./store";

import countries from "i18n-iso-countries";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
countries.registerLocale(require("i18n-iso-countries/langs/de.json"));
countries.registerLocale(require("i18n-iso-countries/langs/es.json"));
countries.registerLocale(require("i18n-iso-countries/langs/nl.json"));
countries.registerLocale(require("i18n-iso-countries/langs/uk.json"));

// Posible states for reducers
export enum ReducerStates {
  IDLE = "IDLE",
  PENDING = "PENDING",
  SUCCEEDED = "SUCCEEDED",
  FAILED = "FAILED",
}

export enum Temperature {
  CELSIUS = "C",
  FAHRENHEIT = "F",
}

export enum TimeFormat {
  TIME24H = "24h",
  TIME12H = "12h",
}

export enum TabTypes {
  CRITICAL = "critical",
  WARNING = "warning",
  UNIT = "unit",
  PAUSED = "paused",
}

export enum EventStatus {
  UNASSIGNED = "unassigned",
  ASSIGNED = "assigned",
  RESOLVED = "resolved",
}

export enum EventThresholds {
  LOWER = "lower",
  UPPER = "upper",
}
export enum ReadStatus {
  READ = "read",
  UNREAD = "unread",
}

export enum LineGraphColors {
  PURPLE = "#9013FE",
  YELLOW = "#F8E71C",
  CYAN = "#50DEE3",
  GREEN = "#82B508",
  DARKRED = "#AF0015",
}

export enum Categories {
  normal = "normal",
  low = "low",
  high = "high",
  highCluster = "high_cluster",
  clusterMember = "cluster_member",
  typical = "typical",
}

export enum AccountCreationSteps {
  Instructions = 1,
  AccountCreation,
  EmailVerification,
  CreatePin,
  SelectCommunity,
}

export enum SettingListKeys {
  community_info = "community_info",
  community_users = "community_users",
  notification_groups = "notification_groups",
  notification_settings = "notification_settings",
  hand_off = "hand_off",
  units_residents = "units_residents",
  organization_info = "organization_info",
  organization_users = "organization_users",
  organization_groups = "organization_groups",
}

export enum RoleTypes {
  admin = "admin",
  caregiver = "caregiver",
  family = "family",
  installer = "installer",
  pro_family = "pro:family",
  org = "org:admin",
}

export enum RoleTitles {
  admin = "administrators",
  caregiver = "caregivers",
  installer = "installers",
}

export enum CommunityTypes {
  aip = "aip",
  pro = "pro",
}

export const StackTypes = {
  MOTION: "motion",
  CONTACT: "contact",
};

export const ReportTypes = {
  ACTIVITY: "activity_report",
};

export const ReportStatusTypes = {
  NOT_CREATED: "not_created",
  QUEUED: "queued",
  STARTED: "started",
  COMPLETED: "completed",
  NO_DATA: "no_data",
  ERROR: "error",
};

export const Views = {
  ROOT: "/",
  LOGIN: "/login",
  RESET_PASSWORD: "/resetPassword",
  DASHBOARD: "/dashboard",
  NOTIFICATIONS: "/notifications",
  ACCOUNT_CREATION: "/accountCreation",
  ANALYTICS: "/analytics",
  ANALYTICS_PRINTABLE: "/analytics/printable",
  ENVIRONMENT_SETTING: "/environmentSettings",
  SETTINGS: "/settings",
  DEVICES: "/devices",
  REPORTS: "/reports",
};

export const EventTypes = {
  discussions_new_message: "discussions_new_message",
  bathroom_visit_count: "bathroom_visit_count",
  heat_index_warning: "heat_index_warning",
  no_morning_motion: "no_morning_motion",
  hub_offline: "hub_offline",
  device_offline: "device_offline",
  battery_low: "battery_low",
  night_bathroom_overstay: "night_bathroom_overstay",
  night_bathroom_aggregate_time: "night_bathroom_aggregate_time",
  night_bathroom_visit_count: "night_bathroom_visit_count",
  activity_monitor_day: "activity_monitor_day",
  activity_monitor_night: "activity_monitor_night",
  help_button_press: "help_button_press",
  exterior_exit: "exterior_exit",
  bathroom_anomaly: "bathroom_anomaly",
  site_exit: "site_exit",
  pause_notification: "pause_notification",
  security_notification: "security_notification",
  unpause_notification: "unpause_notification",
  activity_monitor_kitchen: "activity_monitor_kitchen",
  refrigerator_door_open: "refrigerator_door_open",
  exterior_door_open: "exterior_door_open",
  hand_off_notification: "hand_off_notification",
  bathroom_entry: "bathroom_entry",
  daily_status: "daily_status",
  fall_notification: "fall_notification",
};
export const EventsWithDurationConfig = [
  EventTypes.activity_monitor_day,
  EventTypes.activity_monitor_night,
  EventTypes.night_bathroom_overstay,
  EventTypes.night_bathroom_aggregate_time,
  EventTypes.bathroom_anomaly,
  EventTypes.refrigerator_door_open,
  EventTypes.exterior_door_open,
];
export const EventsWithCountConfig = [EventTypes.night_bathroom_visit_count];
export const EventsWithAllDayConfig = [
  EventTypes.exterior_exit,
  EventTypes.site_exit,
  EventTypes.security_notification,
  EventTypes.exterior_door_open,
];
export const EventsWithTimeConfig = [
  EventTypes.exterior_exit,
  EventTypes.site_exit,
  EventTypes.security_notification,
  EventTypes.exterior_door_open,
  EventTypes.bathroom_entry,
  EventTypes.fall_notification,
];
export const EventsWithTriggerTime = [EventTypes.no_morning_motion];
export const DashboardItemTypes = {
  sleep: "sleep",
  activity: "activity",
  temperature: "temperature",
  kitchen: "kitchen",
  bathroom: "bathroom",
};

export const UnitStatus: {
  [key: string]:
    | "paused"
    | "critical"
    | "criticalResolved"
    | "warning"
    | "warningRead"
    | "normal"
    | "notInstalled"
    | "addNewUnit";
} = {
  paused: "paused",
  critical: "critical",
  criticalResolved: "criticalResolved",
  warning: "warning",
  warningRead: "warningRead",
  normal: "normal",
  notInstalled: "notInstalled",
  addNewUnit: "addNewUnit",
};

export const UnitStatusInstaller = {
  NOT_INSTALLED: "notInstalled",
  NOT_INSTALLED_INSTALLER: "notInstalledInstaller",
};

export const DeviceStatus = {
  DEVICE_OFFLINE: "deviceOffline",
  BATTERY_LOW: "batteryLow",
};

export const UnitTypes = {
  RESIDENT: "resident",
  HELP_AT_HOME: "help_at_home",
  COMMON: "common",
};

export const ContactTypes = {
  FAMILY: "family",
  NEIGHBOR: "neighbor",
  FRIEND: "friend",
  DOCTOR: "doctor",
  NURSE: "nurse",
  USER: "user",
  CAREGIVER: "caregiver",
};

export const PhoneTypes = {
  WORK: "work",
  MOBILE: "mobile",
  HOME: "home",
  PAGER: "pager",
  MAIN: "main",
};
export const DashboardViewTypes = {
  ORGANIZATION: "organization",
  COMMUNITY: "community",
  ALL_NOTIFICATIONS: "all_notifications",
};

export const ContactSensorTypes = {
  contact_count: "contact_count",
  contact: "contact",
  exterior_door: "exterior_door",
};

export const AnalyticsChartTypes = {
  notification: "notification",
  sleep: "sleep",
  bathroom: "bathroom",
  activity: "activity",
  temperature: "temperature",
  kitchen: "kitchen",
};

export const DeviceType = {
  sensor: "sensor",
  outlet: "outlet",
  button: "button",
  bulb: "bulb",
};

export const SensorType = {
  contact_count: "contact_count",
  contact: "contact",
  exterior_door: "exterior_door",
  motion: "motion",
  gateway: "gateway",
  help: "help",
  outlet: "outlet",
  bulb: "bulb",
  radar: "radar",
  temperature: "temperature",
};

export const Manufacturers = {
  SAMJIN: "samjin",
  ERIA: "eria",
  HEIMAN: "heiman",
};

export const SleepStatus = {
  normal: "normal",
  late: "late",
  nightEvent: "night_event",
  noData: "no_data",
};

export const SensorStatus = {
  monitoring: "monitoring",
  learning: "learning",
  test: "test",
  silent: "silent",
};

export enum FilterOrder {
  desc,
  asc,
}

export const ConfigLevelTwo = "2";

export const RESIDENT_FIRST_NAME_LENGTH = 10;
export const NAME_CHARACTER_LIMIT = 64;
export type ChartTypes = keyof typeof AnalyticsChartTypes;

export const EMAIL_CHAR_LIMIT = 20;

export const BASE64_STRING = ":base64:";
export const LIMIT_TO_VALIDATE_BASE64 = 350;
export const BASE64_COMMENT_CHAR_LIMIT = 2048;
export const ISO_TIME_SAVE_FORMAT = "HH:mm";

export const VERIFICATION_CODE_LENGTH = 6;
export const PIN_CODE_LENGTH = 4;
export const EVENT_CARD_HEIGHT = 90;
export const ACTIVE_PANEL_WIDTH = 370;
export const CHART_PAGINATION_LOAD = 7;
export const MINIMUM_MINUTES_ZOOM_RANGE = 10;
export const MAXIMUM_MINUTES_ZOOM_RANGE = 2880;
export const MIN_WIDTH_FOR_TOOLTIP = 22;

export const PHONE_NUMBER_MAX_LENGTH = 20;

export const TEST = "care-api-test";
export const STAGING = "care-api-staging";
export const PRODUCTION = "care-api-prod";
export const DefaultRegionCode = "US";

export const RESOLVED_CLEANUP_FREQUENCY = 60000 * 5; // minutes in ms
export const RESOLVED_EVENT_WINDOW = 24; // hours

export const SupportedLanguages = [
  { id: "en", label: "english" },
  { id: "en-GB", label: "english_GB" },
  { id: "de", label: "german" },
  { id: "es", label: "spanish" },
  { id: "fr", label: "french" },
  { id: "nl", label: "dutch" },
] as const;

export const SupportedShopCountries = ["US", "CA"];

/**
 * Hex opacity cheatsheet
 * Add this code at the end of hex color to add opacity 
  100% — FF
  95% — F2
  90% — E6
  85% — D9
  80% — CC
  75% — BF
  70% — B3
  65% — A6
  60% — 99
  55% — 8C
  50% — 80
  45% — 73
  40% — 66
  35% — 59
  30% — 4D
  25% — 40
  20% — 33
  15% — 26
  10% — 1A
  5% — 0D
  0% — 00
 */

export const brandPrimaryColor = "#09BE86";

// Theme for StackCare Web App
export let appTheme = {
  typography: {
    fontFamily: [
      "GothamRoundedBook",
      "Segoe UI",
      "Helvetica Neue",
      "sans-serif",
    ].join(","),
    body1: {
      lineHeight: 1,
    },
    primaryFontFamily: [
      "GothamRoundedBook",
      "Segoe UI",
      "Helvetica Neue",
      "sans-serif",
    ].join(","),
    secondaryFontFamily: [
      "GothamRoundedMedium",
      "Segoe UI",
      "Helvetica Neue",
      "sans-serif",
    ].join(","),
    tertiaryFontFamily: [
      "GothamRoundedBold",
      "Segoe UI",
      "Helvetica Neue",
      "sans-serif",
    ].join(","),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1430,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: brandPrimaryColor,
      trans: "#7CC5AF",
      contrastText: "#fff",
      light: "#F6F6F6",
    },
    error: {
      main: "#EE3B0C",
      anomaly: "#EE3B0CBF",
      trans: "#EE3B0C40",
      light: "#F4896C",
      lightTrans: "#F4896C80",
      contrastText: "#EE3B0C",
    },
    warning: {
      main: "#EE9B0C",
      light: "#F9C97A",
      lightTrans: "#F4C26C80",
      contrastText: "#EE9B0C",
    },
    paused: {
      main: "#404040",
      light: "#808080",
      trans: "#C0C0C0",
      contrastText: "#404040",
    },
    normal: {
      main: brandPrimaryColor,
      trans: "#7CC5AF",
    },
    metrics: {
      main: "#82B508",
    },
    indicator: {
      main: "#1ADCFF",
    },
    dark: {
      main: "#3B3B3B",
      light: "#4A4A4A",
      dark: "#000",
    },
    light: {
      main: "#E8E8E8",
      light: "#FFF",
    },
    gray: {
      main: "#808080",
      light: "#FFF",
    },
    gradientNight: {
      light: "#33333380",
      dark: "#333333CC",
    },
    gradientDay: {
      light: `${brandPrimaryColor}80`,
      dark: `${brandPrimaryColor}CC`,
    },
    gradientMorning: {
      light: "#09AFBE",
      dark: "#09AFBE80",
    },
    gradientAfternoon: {
      light: "#F5A623",
      dark: "#F5A623B3",
    },
    gradientEvening: {
      light: "#106899",
      dark: "#106899BF",
    },
    activityMotion: {
      main: "#7CC5AF",
    },
    contactBlue: {
      main: "#179FEC",
    },
    noData: {
      main: "#4A4A4A",
    },
    lightGrey: {
      main: "#E9E9EA",
    },
    categoryActivity: {
      main: "#09AFBE",
    },
    categoryBathroom: {
      main: "#106899",
    },
    categoryDoor: {
      main: `${brandPrimaryColor}CC`,
    },
    categoryHelpFall: {
      main: "#EE3B0C",
    },
    categoryTemperature: {
      main: "#F5A623",
    },
  },
  overrides: {
    // Style sheet name ⚛️
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: brandPrimaryColor,
        },
        "&$selected:hover": {
          backgroundColor: brandPrimaryColor,
        },
        "&:hover": {
          backgroundColor: brandPrimaryColor,
        },
      },
      button: {
        "&:hover": {
          backgroundColor: brandPrimaryColor,
        },
      },
    },
    MuiButton: {
      root: {
        transition: "color .01s",
        textTransform: "capitalize" as const,
      },
      label: {
        fontFamily: "GothamRoundedMedium",
        fontSize: "1rem",
      },
    },
    MuiButtonBase: {
      root: {
        borderRadius: " 5px",
      },
    },
  },
  spacing: 8,
  mixins: {
    toolbar: {
      minHeight: "100px",
    },
  },
};

export const getTandemTheme = () => {
  const tandemTheme = JSON.parse(JSON.stringify(appTheme));

  const tandemPrimaryColor = "#7CBCC5";
  tandemTheme.palette = {
    ...tandemTheme.palette,
    primary: {
      main: tandemPrimaryColor,
      trans: "#B1D7DC",
      contrastText: "#fff",
      light: "#F6F6F6",
    },
    error: {
      main: "#FF8981",
      anomaly: "#EE3B0CBF",
      trans: "#EE3B0C40",
      light: "#FFC5C0",
      lightTrans: "#FF9F9C80",
      contrastText: shadeColor("#FF8981", -10),
    },
    warning: {
      main: "#FFB84E",
      light: "#FFDEAC",
      lightTrans: "#FDCB8E80",
      contrastText: shadeColor("#FFB84E", -10),
    },
    gradientDay: {
      light: `${tandemPrimaryColor}80`,
      dark: `${tandemPrimaryColor}CC`,
    },
    activityMotion: {
      main: `${tandemPrimaryColor}E6`,
    },
    normal: {
      main: "#C0C0C0",
      trans: "#D0D0D0",
    },
    metrics: {
      main: "#4D74D9",
    },
    indicator: {
      main: "#4D74D9",
    },
  };
  tandemTheme.typography = {
    ...tandemTheme.typography,
    fontFamily: [
      "ProximaNova",
      "Segoe UI",
      "Helvetica Neue",
      "sans-serif",
    ].join(","),
    body1: {
      lineHeight: 1,
    },
    primaryFontFamily: [
      "ProximaNova",
      "Segoe UI",
      "Helvetica Neue",
      "sans-serif",
    ].join(","),
    secondaryFontFamily: [
      "ProximaNovaMedium",
      "Segoe UI",
      "Helvetica Neue",
      "sans-serif",
    ].join(","),
    tertiaryFontFamily: [
      "ProximaNovaBold",
      "Segoe UI",
      "Helvetica Neue",
      "sans-serif",
    ].join(","),
  };
  tandemTheme.overrides = {
    ...tandemTheme.overrides,
    MuiButton: {
      label: {
        fontFamily: "ProximaNovaMedium",
        fontSize: "1rem",
      },
    },
  };

  tandemTheme.mixins.toolbar.minHeight = "0px";

  return tandemTheme;
};

// Icon by event type
export const iconByEventType = (eventType: string, alt: boolean = false) => {
  switch (eventType) {
    case EventTypes.bathroom_visit_count:
    case EventTypes.bathroom_anomaly:
    case EventTypes.bathroom_entry:
      return Icons.BathroomAnomaly;
    case EventTypes.help_button_press:
      if (alt) {
        return Icons.HelpButton;
      }
      return Icons.HelpButtonOutlined;
    case EventTypes.heat_index_warning:
      return Icons.HeatIndexWarning;
    case EventTypes.night_bathroom_aggregate_time:
    case EventTypes.night_bathroom_overstay:
    case EventTypes.night_bathroom_visit_count:
    case EventTypes.no_morning_motion:
      return Icons.NightEvent;
    case EventTypes.exterior_exit:
    case EventTypes.site_exit:
      return Icons.DoorExit;
    case EventTypes.activity_monitor_night:
    case EventTypes.activity_monitor_day:
    case EventTypes.security_notification:
      return Icons.ActivityEvent;
    case EventTypes.device_offline:
    case EventTypes.hub_offline:
    case EventTypes.battery_low:
      return Icons.GearIcon;
    case EventTypes.pause_notification:
      if (alt) {
        return Icons.PausedUnitWhiteIcon;
      }
      return Icons.PausedUnitIcon;
    case EventTypes.activity_monitor_kitchen:
    case EventTypes.refrigerator_door_open:
      return Icons.KitchenDashboard;
    case EventTypes.exterior_door_open:
      return Icons.DoorOpen;
    case EventTypes.hand_off_notification:
      return Icons.HandOff;
    case EventTypes.discussions_new_message:
    case EventTypes.fall_notification:
      return Icons.FallIcon;
    default:
      return "";
  }
};
// Icon by dashboard item type
export const iconByDashboardItemType = (eventType: string) => {
  switch (eventType) {
    case DashboardItemTypes.bathroom:
      return DashboardIcons.BathroomDashboard;
    case DashboardItemTypes.temperature:
      return DashboardIcons.TemperatureDashboard;
    case DashboardItemTypes.sleep:
      return DashboardIcons.SleepDashboard;
    case DashboardItemTypes.activity:
      return DashboardIcons.ActivityDashboard;
    case DashboardItemTypes.kitchen:
      return DashboardIcons.KitchenDashboard;
    default:
      return "";
  }
};

// Icon by system issue
export const iconBySystemIssue = (event: IEvent) => {
  if (event.system_issues) {
    return NotificationIcons.SystemIssuesIcon;
  } else if (event.is_delayed) {
    return NotificationIcons.DelayedIcon;
  } else {
    return "";
  }
};

// Localized event name by event type
export const i18textByEventType = (eventType: string) => {
  switch (eventType) {
    case EventTypes.bathroom_visit_count:
      return "bathroom_visit_count";
    case EventTypes.bathroom_anomaly:
      return "bathroom_overstay";
    case EventTypes.help_button_press:
      return "help_button_press";
    case EventTypes.heat_index_warning:
      return "temperature_warning";
    case EventTypes.night_bathroom_aggregate_time:
      return "night_bathroom_total_time";
    case EventTypes.night_bathroom_overstay:
      return "night_bathroom_overstay";
    case EventTypes.night_bathroom_visit_count:
      return "night_bathroom_total_visits";
    case EventTypes.no_morning_motion:
      return "up_and_about_check";
    case EventTypes.activity_monitor_night:
      return "night_activity_monitor_title";
    case EventTypes.activity_monitor_day:
      return "day_activity_monitor_title";
    case EventTypes.exterior_exit:
      return "device_type_exterior_door";
    case EventTypes.device_offline:
      return "device_offline";
    case EventTypes.hub_offline:
      return "hub_offline";
    case EventTypes.battery_low:
      return "battery_low";
    case EventTypes.discussions_new_message:
      return "new_discussion_message";
    case EventTypes.site_exit:
      return "resident_exit";
    case EventTypes.pause_notification:
      return "stackcare_paused";
    case EventTypes.security_notification:
      return "security_notification";
    case EventTypes.activity_monitor_kitchen:
      return "activity_monitor_kitchen";
    case EventTypes.refrigerator_door_open:
      return "refrigerator_door_open";
    case EventTypes.exterior_door_open:
      return "exterior_door_open";
    case EventTypes.hand_off_notification:
      return "hand_off";
    case EventTypes.unpause_notification:
      return "unpause_notification";
    case EventTypes.bathroom_entry:
      return "bathroom_entry";
    case EventTypes.daily_status:
      return "daily_status";
    case EventTypes.fall_notification:
      return "fall_notification";
    default:
      return "-";
  }
};

export const getDurationConfigByEventType = (eventType: string) => {
  switch (eventType) {
    // dayActivityDuration 1h - 23h 30m
    case EventTypes.activity_monitor_day:
      return {
        min: 60,
        max: 1410,
      };
    // nightActivityDuration  15 - 4h 30m
    case EventTypes.activity_monitor_night:
      return { min: 15, max: 270 };
    // bathroom overstay 15 - 2h
    case EventTypes.night_bathroom_overstay:
      return { min: 15, max: 120 };
    // nightBathroomOverstayDuration 15m - 2h
    case EventTypes.night_bathroom_aggregate_time:
      return { min: 15, max: 120 };
    // bathroomOverstayDuration 5m - 2h
    case EventTypes.bathroom_anomaly:
      return { min: 5, max: 120 };
    // refrigeratorDoorOpenDuration 5m - 20m
    case EventTypes.refrigerator_door_open:
      return { min: 5, max: 20 };
    //exteriorDoorOpenDuration 5m - 1h
    case EventTypes.exterior_door_open:
      return { min: 5, max: 60 };
    default:
      return { min: 5, max: 60 };
  }
};

export const getCountConfigByEventType = (eventType: string) => {
  switch (eventType) {
    // nightBathroomVisitCount 2 times to 20 times
    case EventTypes.night_bathroom_visit_count:
      return {
        min: 2,
        max: 20,
      };
    default:
      return { min: 2, max: 20 };
  }
};

export const getTriggerConfigByEventType = (eventType: string) => {
  switch (eventType) {
    // Up and about 7:00 AM to 14:00PM
    case EventTypes.no_morning_motion:
      return {
        min: 7,
        max: 14,
      };
    default:
      return { min: 7, max: 14 };
  }
};

// Icon by event type
export const getIconByPhoneType = (phoneType: string) => {
  switch (phoneType) {
    case PhoneTypes.HOME:
      return ContactIcons.PhoneHome;
    case PhoneTypes.WORK:
      return ContactIcons.PhoneWork;
    case PhoneTypes.MOBILE:
      return ContactIcons.PhoneMobile;
    case PhoneTypes.PAGER:
      return ContactIcons.PhonePager;
    case PhoneTypes.MAIN:
      return ContactIcons.PhoneMain;
    default:
      return "";
  }
};

export const temperatureConfig = () => {
  return store.getState().appState.config.temperature;
};

export const celsiusToFahrenheit = (celsius: number) => {
  return (celsius * 9.0) / 5 + 32;
};

// Formats temperature according to configured setting, default is Celsius
export const formatTemperature = (
  celsius: number,
  withFormat: boolean = true
) => {
  const temperatureConfig = store.getState().appState.config.temperature;
  if (temperatureConfig === Temperature.CELSIUS) {
    if (withFormat) {
      return `${truncate(celsius)}°C`;
    } else {
      return parseFloat(truncate(celsius));
    }
  } else {
    const fahrenheitTemp = (celsius * 9) / 5 + 32;
    if (withFormat) {
      return `${truncate(fahrenheitTemp)}°F`;
    } else {
      return parseFloat(truncate(fahrenheitTemp));
    }
  }
};

export const capitalize = (s: string) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const sortAlphabetical = (a?: string | null, b?: string | null) => {
  if (a === undefined || b === undefined) return 0;
  if (a === b)
    // identical? return 0
    return 0;
  else if (a === null)
    // a is null? last
    return 1;
  else if (b === null)
    // b is null? last
    return -1;
  else {
    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: "base",
    });

    return collator.compare(a, b);
  }
};

export const graphColorPicker = (index: number) => {
  // Determines line style, if lesser than 5, straight line, between 6 and 10, dashed line and greater than 10, a dotted line
  const colorsCount = Object.keys(LineGraphColors).length;
  const dashlineStyle =
    index <= colorsCount - 1
      ? // if is lesser than the amount of colors, regular line
        []
      : index > colorsCount - 1 && index <= colorsCount * 2 - 1
      ? // if is lesser than the amount of colors * 2, then dashed line
        [8, 8]
      : // if is greater than the amount of colors * 2, then dotted line
        [3, 3];
  let borderColor = "";
  switch (index % 5) {
    case 0:
      borderColor = LineGraphColors.PURPLE;
      break;
    case 1:
      borderColor = LineGraphColors.YELLOW;
      break;
    case 2:
      borderColor = LineGraphColors.CYAN;
      break;
    case 3:
      borderColor = LineGraphColors.GREEN;
      break;
    case 4:
      borderColor = LineGraphColors.DARKRED;
      break;
    default:
      borderColor = "#000000";
  }
  return { borderColor, dashlineStyle };
};

/* truncate number to 1 decimal  */
export function truncate(value: number) {
  const str = value.toString();
  const ans = str.match(/^-?\d+(?:\.\d{0,1})?/);
  return ans !== null && ans.length > 0 ? ans[0] : `${value}`;
}

/* Truncates the string and replaces with ... for strings longer than n */
export function ellipsis(str: string, n: number) {
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
}

/* Converts a string to utf-8 and then to base64 */
export function utf8_to_b64(str: string) {
  return window.btoa(unescape(encodeURIComponent(str)));
}

export const SYSTEM_RESOLVED_PAUSED = "system_resolved_paused";

export const stackCareBizURL = "http://stackcare.biz";

export const reportTitle = "Activity Report";

export const noBlurWindow = 1.5;
export const noBlurValue = 0.05;

export const midBlurWindow = 6;
export const midBlurValue = 0.4;

export enum MaintenanceTypes {
  normal = "normal",
  maintenanceInProgress = "maintenance_in_progress",
  outageLTE = "outage_lte",
  outageNotifications = "outage_notifications",
  outageData = "outage_data",
  outageBackend = "outage_backend",
  maintenanceData = "maintenance_data",
  maintenanceBackend = "maintenance_backend",
}

export enum ValidBannerTypes {
  maintenanceInProgress = "maintenance_in_progress",
  outageLTE = "outage_lte",
  outageNotifications = "outage_notifications",
  outageData = "outage_data",
  outageBackend = "outage_backend",
}

export const ValidFilters = {
  notification: "notification",
  resident: "resident",
  responder: "responder",
  status: "status",
};

export const chartWeekWindow = 6;
