import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Paper, Typography } from "@material-ui/core";
import { getUnitTitle } from "../dashboard/CommunityUnit";
import { IUnit } from "../../services/dashboard.services";
import { addMonths, eachMonthOfInterval, startOfMonth, sub } from "date-fns";
import { ReportItem } from "./ReportItem";
import { getMonth } from "../../helpers/utils";
import { useSelector } from "react-redux";
import { IAppState } from "../../helpers/store";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    reportsContainer: {
      padding: theme.spacing(6, 7),
      width: "850px",
    },
    sectionTitle: {
      marginTop: 0,
      marginBottom: theme.spacing(4),
      textAlign: "center",
      fontSize: "1.25rem",
      fontWeight: "bold",
    },
    sectionBody: {
      marginTop: theme.spacing(6),
      textAlign: "center",
    },
    reportItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      border: "1px solid",
      borderRadius: "4px",
      height: "35px",
      marginBottom: "10px",
      borderColor: theme.palette.light.main,
    },
  })
);

export function ReportsList(props: IProps) {
  const { selectedUnit } = props;
  const { t } = useTranslation();

  const userLanguage = useSelector(
    (state: IAppState) => state.headerState.user?.language
  );

  /* Hooks */
  const classes = useStyles();
  const listItemText = getUnitTitle(selectedUnit);
  const defaultStartDate = new Date();
  defaultStartDate.setFullYear(defaultStartDate.getFullYear() - 1);

  const timeCreated = selectedUnit.time_created
    ? new Date(selectedUnit.time_created)
    : defaultStartDate;

  const start = new Date(timeCreated.getFullYear(), timeCreated.getMonth(), 1);
  const end = startOfMonth(sub(new Date(), { months: 1 }));

  // For newly created units, the start date will be greater than the end date
  // so we return an empty list instead
  const datesList =
    start > end ? [] : eachMonthOfInterval({ start, end }).reverse();

  const getTitle = (title: string, subtitle: string) => {
    if (subtitle === "") {
      return title;
    }

    return title + " - " + subtitle;
  };

  const nextMonth = addMonths(new Date(), 1);

  return (
    <Paper className={classes.reportsContainer} square>
      <Typography className={classes.sectionTitle}>
        {getTitle(listItemText.unitTitle, listItemText.unitSubtitle)}
      </Typography>
      {datesList.length === 0 && (
        <Typography className={classes.sectionBody}>
          {t("report_available", {
            month: getMonth(nextMonth.getMonth(), userLanguage),
          })}
        </Typography>
      )}
      {datesList?.map((date, index) => {
        return (
          <ReportItem key={index} date={date} selectedUnit={selectedUnit} />
        );
      })}
    </Paper>
  );
}

interface IProps {
  selectedUnit: IUnit;
}
