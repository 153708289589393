import React, { useEffect, useMemo, useRef, useState } from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import { IEvent, IUnit } from "../../services/dashboard.services";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box/Box";
import {
  applySort,
  clearFilters,
  clearSort,
  getEventDataNotifications,
  selectNotification,
  toggleFilter as toggleStateFilter,
} from "./notificationsSlice";
import { useDispatch } from "react-redux";
import { NotificationsTableBody } from "./NotificationsTableBody";
import { NotificationsTableHeader } from "./NotificationsTableHeader";
import useHelpAtHome from "./hooks/useHelpAtHome";
import {
  createData,
  getColumnOrder,
  getHeaderCells,
  TableFields,
} from "./helpers";
import {
  FilterOrder,
  ReducerStates,
  ValidFilters,
} from "../../helpers/constants";
import { Popover } from "@material-ui/core";
import { FilterPicker } from "./filters/FilterPicker";
import { getFilterDefaultValues } from "./filters/helpers";
import { useAppSelector } from "../app/appHooks";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.light.light,
    },
    notificationsTable: {
      width: "100%",
    },
    emptyTable: {
      height: "100%",
    },
  })
);

export interface SortState {
  order: FilterOrder;
  orderBy: TableFields;
}

export function NotificationsTable(props: IProps) {
  const { notifications, units, next } = props;

  /* ---- State ---- */
  const [anchorEl, setAnchorEl] = useState(null);
  const [columnFilter, setColumnFilter] = useState("");

  /* ---- Hooks ---- */
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [allHelpAtHome, someHelpAtHome, someOtherUnitTypes] = useHelpAtHome(
    units
  );

  /* ---- State ---- */
  const [showLoader, setShowLoader] = useState(false);
  const [dataLength, setDataLength] = useState<undefined | number>(undefined);
  const [selected, setSelected] = useState("");

  const [sort, setSort] = useState<SortState[]>([]);

  /* ---- Selectors ---- */
  const selectedNotificationID = useAppSelector(
    (state) => state.notificationsState.selectedNotificationID
  );
  const users = useAppSelector((state) => state.headerState.users);
  const state = useAppSelector((state) => state.notificationsState.state);
  const defaultSort = useAppSelector((state) => state.notificationsState.sort);

  const filterToggleState = useAppSelector(
    (state) => state.notificationsState.toggleFilter
  );

  const filterStatus = useAppSelector(
    (state) => state.notificationsState.filters["status"]
  );

  const isUnassignedFilterActive = filterStatus?.["unassigned"] === true;

  const hasMore = useAppSelector((state) => state.notificationsState.more);
  /* ---- Refs ---- */
  const actionTriggered = useRef(false);

  /* ---- Effects ---- */
  useEffect(() => {
    if (selectedNotificationID) {
      setSelected(selectedNotificationID);
    }
  }, [selectedNotificationID]);

  useEffect(() => {
    setSort(defaultSort);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state === ReducerStates.IDLE) {
      setSort(defaultSort);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (notifications) {
      setDataLength(notifications.length);
    }
  }, [notifications]);

  useEffect(() => {
    if (!dataLength || notifications.length === dataLength) return;
    actionTriggered.current = false;
    setShowLoader(false);
  }, [notifications.length, dataLength]);

  /* ---- Methods ---- */
  const handleClick = (id: string) => {
    setSelected(id);
    dispatch(getEventDataNotifications(id));
    dispatch(selectNotification(id));
  };

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const el = e.target as HTMLElement;
    const bottom = el.scrollHeight - el.scrollTop <= el.clientHeight + 20;
    if (bottom && hasMore && !actionTriggered.current) {
      actionTriggered.current = true;
      setShowLoader(true);
      next();
    }
  };

  const handleSort = (accessor: TableFields) => {
    setSort((prevSort) => {
      const newSort: SortState[] = [...prevSort];

      if (accessor === "created") {
        //check if there is a primary filter selected
        if (prevSort.length > 1) {
          newSort[1] = {
            order:
              prevSort[1].order === FilterOrder.asc &&
              prevSort[1].orderBy === accessor
                ? FilterOrder.desc
                : FilterOrder.asc,
            orderBy: accessor,
          };
        } else {
          //there is other filter selected, reset it and make the created filter the primary
          newSort[0] = {
            order:
              prevSort[0].order === FilterOrder.asc &&
              prevSort[0].orderBy === accessor
                ? FilterOrder.desc
                : FilterOrder.asc,
            orderBy: accessor,
          };
        }
      } else {
        if (prevSort.length > 1) {
          newSort[0] = {
            order:
              prevSort[0].order === FilterOrder.asc &&
              prevSort[0].orderBy === accessor
                ? FilterOrder.desc
                : FilterOrder.asc,
            orderBy: accessor,
          };
        } else {
          newSort[1] = newSort[0];
          newSort[0] = {
            order:
              prevSort[0].order === FilterOrder.asc &&
              prevSort[0].orderBy === accessor
                ? FilterOrder.desc
                : FilterOrder.asc,
            orderBy: accessor,
          };
        }
      }
      dispatch(applySort(newSort));
      return newSort;
    });
  };

  const handleResetSort = () => {
    setSort([{ order: FilterOrder.desc, orderBy: "created" }]);
    dispatch(clearSort());
  };

  const handleToggleFilter = () => {
    dispatch(toggleStateFilter());
  };

  /* Sorting */
  const mixedUnitTypes = someHelpAtHome && someOtherUnitTypes;
  const columnOrder = getColumnOrder(!!allHelpAtHome, !!mixedUnitTypes);
  const headerCells = getHeaderCells(
    columnOrder,
    !!allHelpAtHome,
    !!mixedUnitTypes,
    isUnassignedFilterActive
  );
  const formatedData = useMemo(
    () => createData(columnOrder, notifications, units, users),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notifications, units]
  );

  const handleOpenFilter = (event: any, column: string) => {
    setAnchorEl(event.currentTarget);
    setColumnFilter(column);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const handleClearFilters = () => {
    dispatch(clearFilters());
  };

  const activeFilters = useAppSelector(
    (state) => state.notificationsState.filters
  );

  const filterableColumns = headerCells.filter((column) => column.filterable);

  const defaultValues = useMemo(
    () => getFilterDefaultValues(filterableColumns, users, units),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [users, units]
  );

  const activeFiltersByColumn: { [key: string]: boolean } = {};
  const columnMappings: { [key: string]: string[] } = {
    [ValidFilters.responder]: [ValidFilters.status],
  };

  filterableColumns.forEach((column) => {
    const isFilterActive = (filterId: string) =>
      activeFilters[filterId] !== undefined &&
      JSON.stringify(activeFilters[filterId]) !==
        JSON.stringify(defaultValues[filterId]);

    const filterMappings = columnMappings[column.id] || [];

    activeFiltersByColumn[column.id] = [column.id, ...filterMappings].some(
      isFilterActive
    );
  });

  const open = Boolean(anchorEl);

  const resultsEmpty = notifications.length === 0;

  return (
    <React.Fragment>
      <TableContainer className={classes.container} onScroll={handleScroll}>
        <Table
          stickyHeader
          classes={{
            root: clsx(
              classes.notificationsTable,
              resultsEmpty && classes.emptyTable
            ),
          }}
        >
          <NotificationsTableHeader
            columns={headerCells}
            sort={sort}
            filter={filterToggleState}
            activeFilters={activeFiltersByColumn}
            handleClearFilters={handleClearFilters}
            handleSort={handleSort}
            onOpenFilter={handleOpenFilter}
            handleResetSort={handleResetSort}
            handleToggleFilter={handleToggleFilter}
          />

          <NotificationsTableBody
            rows={formatedData}
            notifications={notifications}
            selected={selected}
            filterOn={filterToggleState}
            handleClick={handleClick}
          />
        </Table>
        {showLoader && (
          <Box
            display="flex"
            width="100%"
            justifyContent="center"
            alignItems="center"
            padding="1"
          >
            <h1>{`${t("loading")}`}</h1>
          </Box>
        )}
      </TableContainer>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseFilter}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ zIndex: 1200 }}
      >
        <div>
          <FilterPicker
            handleCloseFilter={handleCloseFilter}
            handleResetSort={handleResetSort}
            selectedColumn={columnFilter}
          />
        </div>
      </Popover>
    </React.Fragment>
  );
}

interface IProps {
  notifications: IEvent[];
  units?: IUnit[];
  next: () => void;
}
