import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, Button, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  IReport,
  IReportStatus,
  IUnit,
} from "../../services/dashboard.services";
import { IAppState } from "../../helpers/store";
import { downloadReport, generateReport } from "./reportsThunks";
import {
  ReportStatusTypes,
  ReportTypes,
  reportTitle,
} from "../../helpers/constants";
import { getFirebaseDBReference } from "../../helpers/firebase";
import { useTranslation } from "react-i18next";
import { setSelectedReport } from "./reportsSlice";
import { getMonth } from "../../helpers/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    reportItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      border: "1px solid",
      borderRadius: "4px",
      height: "35px",
      marginBottom: "10px",
      borderColor: theme.palette.light.main,
    },
    buttonBox: {
      borderLeft: "1px solid",
      height: "35px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "120px",
      borderColor: theme.palette.light.main,
    },
    submitButton: {
      width: "120px",
      height: "35px",
      "&:hover": {
        backgroundColor: "inherit",
        textDecoration: "inherit",
      },
    },
    buttonText: {
      fontWeight: "bold",
    },
    openText: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
    },
    dateText: {
      paddingLeft: "8px",
    },
  })
);

export function OpenTab(report: IReport) {
  const newTab = window.open("about:blank", "_blank");
  const onLoadHandler = () => {
    if (newTab) {
      newTab.document.write(report.report);
      newTab.document.title = reportTitle;
      newTab.document.close();
      newTab.onload = null;
    }
  };

  if (newTab) {
    if (newTab.document.readyState === "complete") {
      onLoadHandler();
    } else {
      newTab.onload = onLoadHandler;
    }
  } else {
    console.error("Failed to open a new tab");
  }
}

export function ReportItem(props: IProps) {
  const { date, selectedUnit } = props;
  /* Hooks */
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const userLanguage = useSelector(
    (state: IAppState) => state.headerState.user?.language
  );

  const report = useSelector((state: IAppState) =>
    state.reportsState.reports?.find(
      (report) =>
        report.unit_id === selectedUnit.id &&
        report.report_date === date.toISOString().split("T")[0]
    )
  );

  const firebaseAuth = useSelector(
    (state: IAppState) => state.loginState.firebaseAuth
  );
  const [buttonText, setButtonText] = useState(t("create"));
  const [reportStatus, setReportStatus] = useState(
    ReportStatusTypes.NOT_CREATED
  );

  const updateButtonText = (reportStatus: IReportStatus) => {
    if (
      !reportStatus ||
      reportStatus.status === ReportStatusTypes.NOT_CREATED
    ) {
      setButtonText(t("create"));
    } else if (reportStatus.status === ReportStatusTypes.QUEUED) {
      setButtonText(t("queued"));
    } else if (reportStatus.status === ReportStatusTypes.STARTED) {
      setButtonText(t("started"));
    } else if (reportStatus.status === ReportStatusTypes.COMPLETED) {
      setButtonText(t("open"));
    } else if (reportStatus.status === ReportStatusTypes.NO_DATA) {
      setButtonText(t("no_data"));
    } else if (reportStatus.status === ReportStatusTypes.ERROR) {
      setButtonText(t("retry"));
    }
  };

  /* ---- Effects ---- */
  useEffect(() => {
    if (firebaseAuth) {
      const reportsRef = getFirebaseDBReference().ref(
        `communities/${selectedUnit.community_id}/units/` +
          `${selectedUnit.id}/reports/${ReportTypes.ACTIVITY}/${
            date.toISOString().split("T")[0]
          }`
      );

      const listener = reportsRef.on("value", (snapshot) => {
        const reportStatus = snapshot.val();
        if (reportStatus) {
          setReportStatus(reportStatus.status);
          updateButtonText(reportStatus);
        } else {
          setReportStatus(ReportStatusTypes.NOT_CREATED);
          updateButtonText(reportStatus);
        }
      });
      return () => reportsRef.off("value", listener);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUnit, firebaseAuth]);

  const defaultStartDate = new Date();
  defaultStartDate.setFullYear(defaultStartDate.getFullYear() - 1);

  const handleButtonClick = (date: Date) => {
    if (selectedUnit && selectedUnit.community_id) {
      if (!report) {
        const messageData = {
          report_type: ReportTypes.ACTIVITY,
          report_date: date.toISOString().split("T")[0],
          community_id: selectedUnit.community_id,
          unit_id: selectedUnit.id,
        };
        if (
          reportStatus === ReportStatusTypes.NOT_CREATED ||
          reportStatus === ReportStatusTypes.ERROR
        ) {
          dispatch(generateReport(messageData));
        } else if (reportStatus === ReportStatusTypes.COMPLETED) {
          dispatch(downloadReport(messageData));
        }
      } else {
        dispatch(setSelectedReport(report));
        OpenTab(report);
      }
    }
  };

  const isDisabled =
    reportStatus === ReportStatusTypes.STARTED ||
    reportStatus === ReportStatusTypes.QUEUED ||
    reportStatus === ReportStatusTypes.NO_DATA;

  return (
    <Box className={classes.reportItem}>
      <Typography className={classes.dateText}>
        {date.getFullYear() + " " + getMonth(date.getMonth(), userLanguage)}
      </Typography>
      <Box className={classes.buttonBox}>
        <Button
          className={classes.submitButton}
          onClick={() => handleButtonClick(date)}
          disabled={isDisabled}
        >
          <Typography
            className={`${
              reportStatus === ReportStatusTypes.COMPLETED
                ? classes.openText
                : classes.buttonText
            }`}
          >
            {buttonText}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}

interface IProps {
  date: Date;
  selectedUnit: IUnit;
}
