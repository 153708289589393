import React, { useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { useAppDispatch, useAppSelector } from "../app/appHooks";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import { AnalyticsUnitTitle } from "./AnalyticsHeader";
import { isSafari } from "react-device-detect";
import useOnlineStatus from "react-online-hook";

import logo from "../../assets/img/logo_2.png";
import { IUnitChartData } from "./analyticsSlice";
import { AnalyticsChartTypes } from "../../helpers/constants";
import { UnitBathroomChart } from "./charts/UnitBathroomChart";
import { UnitSleepChart } from "./charts/UnitSleepChart";
import { UnitActivityChart } from "./charts/UnitActivityChart";
import { UnitTemperatureChart } from "./charts/UnitTemperatureChart";
import { UnitKitchenActivity } from "./charts/UnitKitchenActivity";
import { getUnitChartData } from "./analyticsThunks";
import { DateTimeShortFormatYMD } from "../../helpers/datetime";
import {
  IBathroomEventArray,
  IUnitKitchenData,
  ISleepData,
  IUnitActivityData,
  ITemperatureData,
  IUnitNotificationData,
} from "../dashboard/eventDataTypes";
import clsx from "clsx";
import { UnitNotificationChart } from "./charts/UnitNotificationChart";

const useStyles = makeStyles<Theme, Boolean>((theme: Theme) =>
  createStyles({
    containerFullHeight: {
      height: "100%",
      // overflowY: "auto",
      padding: theme.spacing(0, 0.5),
      "@media (max-height: 830px)": {
        height: "auto",
      },
      [theme.breakpoints.down("md")]: {
        height: "auto",
      },
      justifyContent: (isPrintable) => (isPrintable ? "center" : "none"),
    },
    forceScrollBar: {
      "&::-webkit-scrollbar": {
        appearance: "none",
        "-webkit-appearance": "none",
        width: "7px",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "4px",
        backgroundColor: "rgba(0,0,0,.5)",
        "-webkit-box-shadow": "0 0 1px rgba(255,255,255,.5)",
      },
    },
    chartCard: {
      backgroundColor: theme.palette.light.light,
      borderRadius: "5px",
      height: "100%",
      maxHeight: (isPrintable) =>
        isPrintable ? (isSafari ? "570px" : "680px") : "370px",
      minHeight: (isPrintable) =>
        isPrintable ? (isSafari ? "520px" : "630px") : "320px",
      margin: theme.spacing(1),
      "@media (min-height: 1000px)": {
        maxHeight: "initial",
      },
    },
    pageBreak: {
      "& > *": {
        maxWidth: (isPrintable) =>
          isPrintable ? (isSafari ? "940px" : "980px") : "auto",
      },
    },
    logoContainer: {
      display: "none",
      alignItems: "center",
      width: "35%",
    },
    logoImg: {
      maxWidth: "100%",
      display: "block",
      height: "auto",
      maxHeight: "40px",
    },
    confidentialContainer: {
      fontSize: 14,
      color: theme.palette.paused.light,
      height: "100%",
      display: "none",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      textAlign: "center",
      WebkitPrintColorAdjust: "exact",
    },
    lastUpdated: {
      fontSize: 12,
      color: theme.palette.paused.light,
      paddingRight: theme.spacing(1.5),
      WebkitPrintColorAdjust: "exact",
    },
    lastUpdatedContainer: {
      display: "none",
      justifyContent: "flex-end",
    },
    sectionTitle: {
      marginBottom: 0,
      marginTop: theme.spacing(2),
      textAlign: "center",
      display: "none",
    },
    bottomBuffer: {
      display: "none",
    },
    [`@media print`]: {
      pageBreak: {
        "& > *:not(:last-child)": {
          pageBreakAfter: "always",
        },
      },
      containerFullHeight: {
        display: "block",
      },
      logoContainer: {
        display: "flex",
      },
      confidentialContainer: {
        display: "flex",
      },
      lastUpdatedContainer: {
        display: "flex",
      },
      sectionTitle: {
        display: "block",
      },
      bottomBuffer: {
        display: "flex",
      },
    },
  })
);

const ChartCard = (props: {
  cardHeight: number;
  children?: React.ReactNode;
  isPrintable: boolean;
}) => {
  const { cardHeight } = props;
  /* Hooks */
  const classes = useStyles(props.isPrintable);

  return (
    <div className={classes.chartCard} style={{ height: `${cardHeight}px` }}>
      {props.children}
    </div>
  );
};

const getGridLayout = (unitChartData?: IUnitChartData) => {
  if (unitChartData === undefined) return [];

  let chartDataArr: {
    data:
      | IBathroomEventArray
      | IUnitKitchenData
      | ISleepData[]
      | IUnitActivityData
      | ITemperatureData
      | IUnitNotificationData;
    chartType: string;
  }[] = [];
  Object.keys(AnalyticsChartTypes).forEach((chartType) => {
    if (chartType === AnalyticsChartTypes.bathroom) {
      unitChartData.bathroom.forEach((zone) => {
        chartDataArr.push({
          data: zone,
          chartType,
        });
      });
    }
    if (
      chartType === AnalyticsChartTypes.kitchen &&
      Object.keys(unitChartData.kitchen).length > 0
    ) {
      chartDataArr.push({
        data: unitChartData.kitchen,
        chartType,
      });
    }

    if (chartType === AnalyticsChartTypes.activity) {
      chartDataArr.push({
        data: unitChartData.activity,
        chartType,
      });
    }
    if (chartType === AnalyticsChartTypes.sleep) {
      chartDataArr.push({
        data: unitChartData.sleep,
        chartType,
      });
    }
    if (chartType === AnalyticsChartTypes.temperature) {
      chartDataArr.push({
        data: unitChartData.temperature,
        chartType,
      });
    }
    if (chartType === AnalyticsChartTypes.notification) {
      chartDataArr.push({
        data: unitChartData.notification,
        chartType,
      });
    }
  });
  return chartDataArr;
};

export enum ChartMode {
  maximized,
  minimized,
}

export const ChartPicker = (props: {
  type: string;
  data: any;
  mode: ChartMode;
  pagination?: { startTime: string; endTime: string };
  lastUpdated?: string;
  isPrintable: boolean;
  multipleBathrooms?: boolean;
}) => {
  const {
    type,
    data,
    mode,
    pagination,
    lastUpdated,
    isPrintable,
    multipleBathrooms,
  } = props;
  switch (type) {
    case AnalyticsChartTypes.sleep:
      return (
        <UnitSleepChart
          chartData={data}
          lastUpdatedTime={lastUpdated}
          mode={mode}
          isPrintable={isPrintable}
        />
      );
    case AnalyticsChartTypes.bathroom:
      return (
        <UnitBathroomChart
          chartData={data}
          lastUpdatedTime={lastUpdated}
          mode={mode}
          isPrintable={isPrintable}
          multipleBathrooms={multipleBathrooms}
        />
      );
    case AnalyticsChartTypes.activity:
      return (
        <UnitActivityChart
          chartData={data}
          lastUpdatedTime={lastUpdated}
          mode={mode}
          pagination={pagination}
          isPrintable={isPrintable}
        />
      );
    case AnalyticsChartTypes.temperature:
      return (
        <UnitTemperatureChart
          chartData={data}
          lastUpdatedTime={lastUpdated}
          mode={mode}
          pagination={pagination}
          isPrintable={isPrintable}
        />
      );
    case AnalyticsChartTypes.kitchen:
      return (
        <UnitKitchenActivity
          chartData={data}
          lastUpdatedTime={lastUpdated}
          mode={mode}
          isPrintable={isPrintable}
        />
      );
    case AnalyticsChartTypes.notification:
      return (
        <UnitNotificationChart
          chartData={data}
          lastUpdatedTime={lastUpdated}
          mode={mode}
          pagination={pagination}
          isPrintable={isPrintable}
        />
      );
    default:
      return null;
  }
};

const MINUTE_MS = 300000;

export const AnalyticsViewerCharts = (props: IProps) => {
  const { cardHeight, lastUpdated, listItemText, isPrintable } = props;

  /* Hooks */
  const classes = useStyles(isPrintable);
  const dispatch = useAppDispatch();
  const { isOnline } = useOnlineStatus();
  const { t } = useTranslation();
  const selectedUnit = useAppSelector(
    (state) => state.analyticsState.selectedUnit
  );

  const unitData = useAppSelector(
    (state) => state.analyticsState.selectedUnitData
  );

  const chartDataArr = getGridLayout(unitData);

  const gridLayout = chartDataArr.length > 4 ? 4 : 6;

  // Request info for chart every 5 minutes
  useEffect(() => {
    const interval = setInterval(() => {
      if (isOnline && selectedUnit !== undefined) {
        dispatch(getUnitChartData(selectedUnit));
      }
    }, MINUTE_MS);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isOnline, selectedUnit]);

  const hasMultipleBathrooms =
    chartDataArr.reduce((prevVal, currVal) => {
      if (currVal.chartType === AnalyticsChartTypes.bathroom) {
        return prevVal + 1;
      }
      return prevVal;
    }, 0) > 1;

  return (
    <React.Fragment>
      {unitData && (
        <Grid
          container
          className={clsx(
            classes.containerFullHeight,
            classes.forceScrollBar,
            classes.pageBreak
          )}
        >
          {chartDataArr.map((chartCardData, index) => (
            <Grid
              id={`${chartCardData.chartType}${index}`}
              item
              xs={12}
              lg={isPrintable ? 12 : gridLayout}
              key={`${chartCardData.chartType}${index}`}
            >
              <AnalyticsUnitTitle
                listItemText={listItemText}
                className={classes.sectionTitle}
              />
              <ChartCard cardHeight={cardHeight} isPrintable={isPrintable}>
                <ChartPicker
                  type={chartCardData.chartType}
                  data={chartCardData.data}
                  mode={ChartMode.minimized}
                  lastUpdated={lastUpdated}
                  isPrintable={isPrintable}
                  multipleBathrooms={
                    chartCardData.chartType === AnalyticsChartTypes.bathroom
                      ? hasMultipleBathrooms
                      : undefined
                  }
                />
              </ChartCard>
              {isPrintable && (
                <Grid container>
                  <Grid item xs={4}>
                    <div className={classes.logoContainer}>
                      <img
                        src={logo}
                        alt="StackCare Logo"
                        className={classes.logoImg}
                        draggable={false}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <Box className={classes.confidentialContainer}>
                      {t("confidential")}
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    {lastUpdated && (
                      <div className={classes.lastUpdatedContainer}>
                        <span className={classes.lastUpdated}>{`${t(
                          "last_updated"
                        )}: ${DateTimeShortFormatYMD(
                          lastUpdated ?? ""
                        )}`}</span>
                      </div>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          ))}
        </Grid>
      )}
    </React.Fragment>
  );
};

interface IProps {
  cardHeight: number;
  lastUpdated?: string;
  listItemText?: { unitTitle: string; unitSubtitle: string };
  isPrintable: boolean;
}
