import { IPhoneNumber } from "../../../services/header.services";
import { UnitPhoneNumber } from "./PhoneNumberPicker";
import {
  getCountryCodeForRegionCode,
  parsePhoneNumber,
} from "awesome-phonenumber";
import { DefaultRegionCode } from "../../../helpers/constants";

export const adjustPhoneNumber = <T extends UnitPhoneNumber>(
  phone: T,
  userType?: string
): T => {
  const adjustedPhone = { ...phone };
  if (
    adjustedPhone.phone_number === null ||
    adjustedPhone.phone_number === undefined
  )
    return phone;

  const phoneNumber = parsePhoneNumber(
    adjustedPhone.phone_number,
    adjustedPhone.country_code ?? DefaultRegionCode
  );
  adjustedPhone.user_type =
    userType === "resident" || userType === "user" ? userType : "";
  adjustedPhone.phone_number = phoneNumber.getNumber("significant");
  if (adjustedPhone.country_code) {
    adjustedPhone.country_code = String(
      getCountryCodeForRegionCode(adjustedPhone.country_code)
    );
  }
  return adjustedPhone;
};

type UnitPhoneNumberUpdate = UnitPhoneNumber & { id?: string | null };

export enum phoneOperations {
  add,
  delete,
  update,
  same,
}

const getPhonePriority = (operation: phoneOperations) => {
  switch (operation) {
    case phoneOperations.add:
    case phoneOperations.update:
    case phoneOperations.same:
      return 1;
    case phoneOperations.delete:
      return 2;
  }
};

const sortPhoneOperations = (a: number, b: number) => {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

// Check if the phone number changed compared to the current version
export const checkPhoneToUpdate = (
  oldPhoneNumbers: IPhoneNumber[],
  phonerNumber: UnitPhoneNumberUpdate,
  userType: string
): boolean => {
  let needsToUpdate = false;
  const oldPhoneMatch = oldPhoneNumbers.find(
    (oldPhone) => oldPhone.id === phonerNumber.id
  );
  if (oldPhoneMatch) {
    const newPhoneNumber = adjustPhoneNumber(phonerNumber, userType);
    if (
      oldPhoneMatch.phone_number !== newPhoneNumber.phone_number &&
      newPhoneNumber.phone_number
    ) {
      needsToUpdate = true;
    }
    if (
      newPhoneNumber.phone_type !== oldPhoneMatch.phone_type &&
      newPhoneNumber.phone_number
    ) {
      needsToUpdate = true;
    }
  }
  return needsToUpdate;
};

export const getPhoneOperationsArr = (
  oldPhoneNumbers: IPhoneNumber[] | null | undefined,
  updatedPhones: readonly [UnitPhoneNumberUpdate, UnitPhoneNumberUpdate],
  userType: string
): {
  phone: UnitPhoneNumberUpdate;
  op: phoneOperations;
}[] => {
  const phoneOperation: {
    phone: UnitPhoneNumberUpdate;
    op: phoneOperations;
  }[] = [];

  updatedPhones.forEach((phone) => {
    // add
    if (phone.id === null && phone.phone_number) {
      phoneOperation.push({ phone, op: phoneOperations.add });
    }
    //delete
    else if (
      phone.id !== null &&
      (phone.phone_number === "" || phone.phone_number === null)
    ) {
      phoneOperation.push({ phone, op: phoneOperations.delete });
    }
    // update
    else if (
      oldPhoneNumbers &&
      checkPhoneToUpdate(oldPhoneNumbers, phone, userType)
    ) {
      phoneOperation.push({ phone, op: phoneOperations.update });
    }
    // Did not change
    else if (phone.id !== null && phone.phone_number) {
      phoneOperation.push({ phone, op: phoneOperations.same });
    }
  });

  let priority = 1;
  phoneOperation.forEach((element) => {
    if (
      element.op === phoneOperations.update ||
      element.op === phoneOperations.add ||
      element.op === phoneOperations.same
    ) {
      element.phone.priority = priority;
      priority++;
    }
  });
  return phoneOperation.sort((a, b) =>
    sortPhoneOperations(getPhonePriority(a.op), getPhonePriority(b.op))
  );
};
