import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Divider, Grid, IconButton } from "@material-ui/core";
import { AnalyticsIcons, DashboardIcons } from "../../../helpers/iconImports";
import {
  IBathroomEventData,
  IKitchenCountData,
  INotificationData,
  ISleepData,
  ITemperatureData,
  IUnitActivityData,
  IUnitKitchenData,
  IUnitNotificationData,
} from "../../dashboard/eventDataTypes";
import {
  AnalyticsChartTypes,
  capitalize,
  Categories,
  EventThresholds,
  formatTemperature,
  truncate,
} from "../../../helpers/constants";
import {
  DateMonthDay,
  DateTimeRelativeFormat,
  DateRelativeFormat,
  DateTimeISO,
  DateTimeNow,
  ISODateRelativeMonthDay,
} from "../../../helpers/datetime";
import clsx from "clsx";
import i18n from "../../../i18n";
import {
  getTimeAndComplement,
  valueFormater,
} from "../../dashboard/eventDetailHelpers";
import { useDispatch } from "react-redux";
import { minimizeAnalyticsChart } from "../analyticsSlice";
import { useAppSelector } from "../../app/appHooks";
import { ChartMode } from "../AnalyticsViewerCharts";
import {
  CHART_DATA_LIMIT_MAXIMIZED,
  CHART_DATA_LIMIT_MINIMIZED,
} from "../AnalyticsViewer";
import { expandAnalyticsChart } from "../analyticsThunks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chartHeader: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      margin: theme.spacing(0.5, 0),
      padding: theme.spacing(0, 1),
    },
    chartTitle: {
      fontFamily: theme.typography.tertiaryFontFamily,
      fontSize: theme.typography.h6.fontSize,
      color: theme.palette.primary.main,
      margin: theme.spacing(0.5, 0, 0, 0),
      textAlign: "center",
      WebkitPrintColorAdjust: "exact",
      ColorAdjust: "exact",
    },
    chartDetails: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      WebkitPrintColorAdjust: "exact",
      ColorAdjust: "exact",
    },
    chartIcon: {
      display: "flex",
      margin: "auto",
      height: "20px",
    },
    detailContainedLeft: {
      display: "flex",
      flexDirection: "column",
      textAlign: "left",
      WebkitPrintColorAdjust: "exact",
      ColorAdjust: "exact",
    },
    detailContainedRight: {
      display: "flex",
      flexDirection: "column",
      textAlign: "right",
      padding: theme.spacing(0.5),
      WebkitPrintColorAdjust: "exact",
      ColorAdjust: "exact",
    },
    normal: {
      color: theme.palette.metrics.main,
    },
    warning: {
      color: theme.palette.warning.main,
    },
    critical: {
      color: theme.palette.error.main,
    },
    noData: {
      color: theme.palette.noData.main,
    },
    valueFormat: {
      fontSize: theme.typography.h5.fontSize,
      fontFamily: theme.typography.tertiaryFontFamily,
    },
    unitFormat: {
      fontSize: theme.typography.caption.fontSize,
    },
    category: {},
    noVisible: {
      visibility: "hidden",
    },
    container: {
      position: "relative",
      "&:hover": {
        opacity: 1,
      },
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      height: "calc(100% - 3px)",
      width: "100%",
      opacity: 0,
      transition: ".2s ease",
      backgroundColor: "rgba(255, 255, 255, .15)",
      "backdrop-filter": "blur(5px)",
      "@supports ( -moz-appearance:none )": {
        backgroundColor: theme.palette.light.light,
      },
      "&:hover": {
        opacity: 1,
      },
      cursor: "pointer",
    },
    content: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    text: {
      fontSize: theme.typography.h6.fontSize,
      textAlign: "center",
    },
    iconButtonRoot: {
      padding: theme.spacing(0.5),
      "&:hover": {
        backgroundColor: `unset`,
      },
    },
    toggleSizeIcon: {
      height: "17px",
      width: "17px",
    },
    divider: {
      height: "3px",
    },
  })
);

type DetailColors = "normal" | "warning" | "critical" | "noData";

enum DetailColorValues {
  normal = "normal",
  warning = "warning",
  critical = "critical",
  noData = "noData",
}
interface IChartHeaderDetails {
  valueDetail: { value: string; unit: string; date: string };
  category: string;
  color: DetailColors;
}

const getChartIcon = (type: string) => {
  const chartIcons: { [key: string]: string } = {
    sleep: DashboardIcons.SleepDashboard,
    bathroom: DashboardIcons.BathroomDashboard,
    activity: DashboardIcons.ActivityDashboard,
    temperature: DashboardIcons.TemperatureDashboard,
    kitchen: DashboardIcons.KitchenDashboard,
    notification: DashboardIcons.NotificationsDashboard,
  };
  return chartIcons[type];
};

const getChartTitle = (type: string) => {
  const charTitle: { [key: string]: string } = {
    sleep: "sleep",
    bathroom: "bathroom_visits",
    activity: "motion_activity",
    temperature: "temperature",
    kitchen: "contact_type_refrigerator",
    notification: "notification",
  };
  return charTitle[type];
};

const getSleepHeaderDetails = (
  data: string,
  mode: ChartMode,
  isPrintable: boolean,
  lastUpdatedTime?: string,
  communityUnitTimezone?: string
) => {
  const sleepData: ISleepData[] = JSON.parse(data);
  // Define default values
  const valueDetail = { value: "-", unit: "", date: "" };
  const category = "";
  let color: DetailColors = DetailColorValues.normal;
  if (lastUpdatedTime) {
    valueDetail.date = DateTimeRelativeFormat(
      lastUpdatedTime,
      isPrintable,
      communityUnitTimezone
    );
  }
  if (mode === ChartMode.maximized) {
    const filteredArr = sleepData.filter((item) => item.duration !== null);
    valueDetail.unit = i18n.t("hours_average");
    if (filteredArr.length !== 0) {
      const average =
        filteredArr.reduce((prevVal, currVal, index) => {
          if (currVal.duration) {
            // Convert from millis to hours
            const duration = Number(
              truncate(currVal.duration / (1000 * 60 * 60))
            );
            return duration + prevVal;
          }
          return prevVal;
        }, 0) / filteredArr.length;
      valueDetail.value = `${truncate(average)}`;
    }
    return { valueDetail, category, color };
  }

  // If we have information for the most recent date, then we show date, if not, date of last updated
  if (Array.isArray(sleepData) && sleepData.length > 0) {
    const mostRecentSleepItem = sleepData[0];
    valueDetail.date = DateRelativeFormat(
      mostRecentSleepItem.date,
      isPrintable,
      communityUnitTimezone
    );
    if (
      mostRecentSleepItem.gtb_time &&
      mostRecentSleepItem.uaa_time &&
      mostRecentSleepItem.duration
    ) {
      const durationMinutes = (mostRecentSleepItem.duration || 0) / (1000 * 60);
      valueDetail.value = valueFormater(durationMinutes, false);
      valueDetail.unit = i18n.t("hours");
    }
  }
  return { valueDetail, category, color };
};

const getTemperatureHeaderDetails = (
  data: string,
  isPrintable: boolean,
  optionalData: {
    isCurrent?: boolean;
    lastUpdatedTime?: string;
  },
  communityUnitTimezone?: string
) => {
  const { isCurrent, lastUpdatedTime } = optionalData;
  const temperatureData: ITemperatureData = JSON.parse(data);
  const thresholdValue = temperatureData?.typical_chart?.threshold;
  let temperature = null; // temperatureData?.typical_chart.temperature;

  // Define default values
  const valueDetail = { value: "-", unit: "", date: "" };
  let category = "";
  let color: DetailColors = DetailColorValues.normal;
  if (lastUpdatedTime) {
    valueDetail.date = DateTimeRelativeFormat(
      lastUpdatedTime,
      isPrintable,
      communityUnitTimezone
    );
  }
  if (isCurrent !== undefined && !isCurrent)
    return { valueDetail, category, color };

  if (thresholdValue !== undefined && thresholdValue !== null) {
    // Get system unit
    if (thresholdValue === EventThresholds.LOWER) {
      valueDetail.unit = i18n.t("lowest").toLowerCase();
      category = "low_temp";
      color = DetailColorValues.critical;
    } else if (thresholdValue === EventThresholds.UPPER) {
      valueDetail.unit = i18n.t("highest").toLowerCase();
      category = "high_temp";
      color = DetailColorValues.critical;
    }
    temperature = temperatureData?.typical_chart?.threshold_temp;
  } else if (thresholdValue === null) {
    temperature = temperatureData?.typical_chart?.temperature;
    color = DetailColorValues.normal;
    category = Categories.typical;
    valueDetail.unit = i18n.t("average");
  }

  if (temperature) {
    valueDetail.value = `${formatTemperature(temperature)}`;
  }

  return { valueDetail, category, color };
};

const getActivityHeaderDetails = (
  data: string,
  isPrintable: boolean,
  optionalData: {
    isCurrent?: boolean;
    lastUpdatedTime?: string;
  },
  communityUnitTimezone?: string
) => {
  const { isCurrent, lastUpdatedTime } = optionalData;
  const activityData: IUnitActivityData = JSON.parse(data);
  const motionDataLastMotion = activityData?.last_motion_event_time;
  // debugger;
  // Define default values
  let valueDetail = { value: "-", unit: "", date: "" };
  const category = "";
  const color: DetailColors = DetailColorValues.normal;
  if (lastUpdatedTime) {
    valueDetail.date = DateTimeRelativeFormat(
      lastUpdatedTime,
      isPrintable,
      communityUnitTimezone
    );
  }
  if (motionDataLastMotion) {
    valueDetail.date = DateTimeRelativeFormat(
      motionDataLastMotion,
      isPrintable,
      communityUnitTimezone
    );
  }

  if (isCurrent !== undefined && !isCurrent)
    return { valueDetail, category, color };

  if (motionDataLastMotion) {
    const timeValues = getTimeAndComplement(
      motionDataLastMotion,
      communityUnitTimezone
    );
    valueDetail.value = timeValues.value;
    valueDetail.unit = timeValues.complementText;
  }
  return { valueDetail, category, color };
};

export const _isClusterMember = (item: IBathroomEventData) => {
  return (
    item.day?.outlier_category === Categories.clusterMember ||
    item.night?.outlier_category === Categories.clusterMember ||
    item.total?.outlier_category === Categories.clusterMember
  );
};

const _isOutlier = (item: IBathroomEventData, category: string) => {
  return item.day?.indiv_category ||
    item.night?.indiv_category ||
    item.total?.indiv_category
    ? item.day?.indiv_category === category ||
        item.night?.indiv_category === category ||
        item.total?.indiv_category === category
    : item.day?.indiv_outlier ||
      item.night?.indiv_outlier ||
      item.total?.indiv_outlier
    ? item.day?.indiv_outlier?.valueOf() === true ||
      item.night?.indiv_outlier?.valueOf() === true ||
      item.total?.indiv_outlier?.valueOf() === true
    : false;
};

const getBathroomHeaderDetails = (
  data: string,
  mode: ChartMode,
  isPrintable: boolean,
  lastUpdatedTime?: string,
  communityUnitTimezone?: string
) => {
  const bathroomData: IBathroomEventData[] = JSON.parse(data);

  if (bathroomData.length === 0)
    return {
      valueDetail: {
        value: "-",
        unit: i18n.t("visits").toLowerCase(),
        date:
          lastUpdatedTime !== undefined
            ? DateTimeRelativeFormat(
                lastUpdatedTime,
                isPrintable,
                communityUnitTimezone
              )
            : "",
      },
      category: "no_data",
      color: DetailColorValues.noData as DetailColors,
    };

  // Sort data by date
  const sortedBathroomData = bathroomData
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    .slice(
      -(mode === ChartMode.maximized
        ? CHART_DATA_LIMIT_MAXIMIZED
        : CHART_DATA_LIMIT_MINIMIZED)
    )
    .reverse();

  // Find first item for which total is not null or undefined
  const totalIndex = sortedBathroomData.findIndex(
    (bathroomDataItem) => bathroomDataItem.total
  );

  // Define default values
  let valueDetail = {
    value: "-",
    unit: i18n.t("visits").toLowerCase(),
    date: "",
  };
  let category = "";
  let color: DetailColors = DetailColorValues.normal;
  if (lastUpdatedTime) {
    valueDetail.date = DateTimeRelativeFormat(
      lastUpdatedTime,
      isPrintable,
      communityUnitTimezone
    );
  }
  if (mode === ChartMode.maximized) {
    const filteredArr = sortedBathroomData.filter(
      (item) => item.total !== null && item.total !== undefined
    );
    valueDetail.unit = i18n.t("visits_average");
    if (filteredArr.length !== 0) {
      const average =
        filteredArr.reduce((prevVal, currVal) => {
          if (
            currVal.total &&
            currVal.total.visits !== null &&
            currVal.total.visits !== undefined
          ) {
            return currVal.total.visits + prevVal;
          }
          return prevVal;
        }, 0) / filteredArr.length;
      valueDetail.value = `${truncate(average)}`;
    }
    return { valueDetail, category, color };
  }

  // Check if there is data in the array
  const noData = !sortedBathroomData.some(
    (bathroomDataItem) =>
      bathroomDataItem.day || bathroomDataItem.night || bathroomDataItem.total
  );

  if (totalIndex >= 0) {
    const bathroomDataItem = sortedBathroomData[totalIndex];
    if (
      bathroomDataItem &&
      bathroomDataItem.total &&
      bathroomDataItem.total.visits
    ) {
      valueDetail.value = `${bathroomDataItem.total.visits}`;
      valueDetail.date = DateRelativeFormat(
        bathroomDataItem.date,
        isPrintable,
        communityUnitTimezone
      );
    }

    const isClusterMember = _isClusterMember(bathroomDataItem);
    const isHighOutlier = _isOutlier(bathroomDataItem, Categories.high);
    const isLowOutlier = _isOutlier(bathroomDataItem, Categories.low);

    if (isClusterMember || isHighOutlier) {
      category = "high_count";
      color = DetailColorValues.warning;
    } else if (isLowOutlier) {
      category = "low_count";
      color = DetailColorValues.warning;
    } else {
      category = Categories.typical;
    }
  }
  if (noData) {
    if (sortedBathroomData.length > 0) {
      valueDetail.date = DateRelativeFormat(
        sortedBathroomData[0].date,
        isPrintable,
        communityUnitTimezone
      );
    }
    category = "no_data";
    color = DetailColorValues.noData;
  }
  return { valueDetail, category, color };
};

const getKitchenHeaderDetails = (
  data: string,
  mode: ChartMode,
  isPrintable: boolean,
  lastUpdatedTime?: string,
  communityTimezone?: string
) => {
  const kitchenData: IUnitKitchenData = JSON.parse(data);
  const weeklyCount: IKitchenCountData[] = kitchenData.weekly_count ?? [];
  const fridgeCount = kitchenData?.fridge_count;
  const motionDataLastMotion = kitchenData?.last_motion_event_time;
  // Define default values
  let valueDetail = {
    value: "-",
    unit: i18n.t("openings").toLowerCase(),
    date: "",
  };
  let category = "";
  let color: DetailColors = DetailColorValues.normal;
  if (lastUpdatedTime) {
    valueDetail.date = DateTimeRelativeFormat(
      lastUpdatedTime,
      isPrintable,
      communityTimezone
    );
  }
  if (mode === ChartMode.maximized) {
    valueDetail.unit = i18n.t("openings_average");
    if (weeklyCount.length !== 0) {
      const average =
        weeklyCount.reduce((prevVal, currVal) => {
          const totalDay =
            currVal.morning +
            currVal.afternoon +
            currVal.evening +
            currVal.night;
          return totalDay + prevVal;
        }, 0) / weeklyCount.length;
      valueDetail.value = `${truncate(average)}`;
    }
    return { valueDetail, category, color };
  }
  if (motionDataLastMotion) {
    valueDetail.date = DateTimeRelativeFormat(
      motionDataLastMotion,
      isPrintable,
      communityTimezone
    );
  }

  if (kitchenData.weekly_count && kitchenData.weekly_count.length > 0) {
    const mostRecentKitchenItem = kitchenData.weekly_count.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    )[0];
    valueDetail.date = DateRelativeFormat(
      mostRecentKitchenItem.date,
      isPrintable,
      communityTimezone
    );
  }

  if (fridgeCount) {
    valueDetail.value = `${fridgeCount}`;
  }
  return { valueDetail, category, color };
};

const getNotificationHeaderDetails = (
  data: string,
  isPrintable: boolean,
  lastUpdatedTime?: string,
  communityTimezone?: string
) => {
  const notificationData: IUnitNotificationData = JSON.parse(data);
  const weeklyCount: INotificationData[] = notificationData.weekly_count ?? [];
  let valueDetail = {
    value: "-",
    unit: i18n.t("count").toLowerCase(),
    date: "",
  };
  let category = "";
  let color: DetailColors = DetailColorValues.normal;
  if (lastUpdatedTime) {
    valueDetail.date = DateTimeRelativeFormat(
      lastUpdatedTime,
      isPrintable,
      communityTimezone
    );
  }
  if (weeklyCount.length !== 0) {
    const mostRecent = weeklyCount[weeklyCount.length - 1];

    const totalDay =
      mostRecent.activity +
      mostRecent.bathroom +
      mostRecent.door +
      mostRecent.help_fall +
      mostRecent.temperature;

    valueDetail.value = `${truncate(totalDay)}`;
  }

  return { valueDetail, category, color };
};

const getHeaderInformation = (
  type: string,
  data: any,
  mode: ChartMode,
  isPrintable: boolean,
  optionalData: {
    pagination?: {
      localStart: string;
      localEnd: string;
    };
    lastUpdatedTime?: string;
    isCurrent?: boolean;
  },
  communityUnitTimezone?: string
): IChartHeaderDetails => {
  const { pagination, lastUpdatedTime, isCurrent } = optionalData;

  let defaultHeaderInfo = {
    valueDetail: { value: "-", unit: "", date: "" },
    category: "",
    color: DetailColorValues.normal as DetailColors,
  };

  switch (type) {
    case AnalyticsChartTypes.sleep:
      defaultHeaderInfo = getSleepHeaderDetails(
        JSON.stringify(data),
        mode,
        isPrintable,
        lastUpdatedTime,
        communityUnitTimezone
      );
      break;
    case AnalyticsChartTypes.bathroom:
      defaultHeaderInfo = getBathroomHeaderDetails(
        JSON.stringify(data),
        mode,
        isPrintable,
        lastUpdatedTime,
        communityUnitTimezone
      );
      break;
    case AnalyticsChartTypes.activity:
      defaultHeaderInfo = getActivityHeaderDetails(
        JSON.stringify(data),
        isPrintable,
        {
          lastUpdatedTime,
          isCurrent,
        },
        communityUnitTimezone
      );
      break;
    case AnalyticsChartTypes.temperature:
      defaultHeaderInfo = getTemperatureHeaderDetails(
        JSON.stringify(data),
        isPrintable,
        {
          lastUpdatedTime,
          isCurrent,
        },
        communityUnitTimezone
      );
      break;
    case AnalyticsChartTypes.kitchen:
      defaultHeaderInfo = getKitchenHeaderDetails(
        JSON.stringify(data),
        mode,
        isPrintable,
        lastUpdatedTime,
        communityUnitTimezone
      );
      break;
    case AnalyticsChartTypes.notification:
      defaultHeaderInfo = getNotificationHeaderDetails(
        JSON.stringify(data),
        isPrintable,
        lastUpdatedTime,
        communityUnitTimezone
      );
      break;
    default:
      defaultHeaderInfo = {
        valueDetail: { value: "-", unit: "", date: "" },
        category: "",
        color: DetailColorValues.normal as DetailColors,
      };
  }

  if (mode === ChartMode.maximized && pagination) {
    let startTime = DateTimeISO(pagination.localStart, communityUnitTimezone);
    let endTime = DateTimeISO(pagination.localEnd, communityUnitTimezone);

    const isDateOnlyChart =
      type === AnalyticsChartTypes.sleep ||
      type === AnalyticsChartTypes.bathroom ||
      type === AnalyticsChartTypes.kitchen;

    if (isDateOnlyChart) {
      startTime = startTime.toUTC();
      endTime = endTime.toUTC();
    }

    // When the charts only use dates (without time) to display data, we don't care about timezones
    const now = isDateOnlyChart ? DateTimeNow().toUTC() : DateTimeNow();
    // If start time is today, then show 'Today'
    if (startTime.toISODate() === now.toISODate()) {
      defaultHeaderInfo.valueDetail.date = ISODateRelativeMonthDay(startTime);
    } else {
      // If start time is different than end time, show interval
      if (startTime.day !== endTime.day) {
        defaultHeaderInfo.valueDetail.date = `${DateMonthDay(startTime)}`;
        // If end time is today, then show that indicator
        if (endTime.toISODate() === DateTimeNow().toISODate()) {
          defaultHeaderInfo.valueDetail.date += ` - ${DateMonthDay(
            endTime
          )} (${ISODateRelativeMonthDay(endTime)})`;
        } else {
          defaultHeaderInfo.valueDetail.date += ` - ${DateMonthDay(endTime)}`;
        }
      }
      // If not, just show start time date
      else {
        defaultHeaderInfo.valueDetail.date = DateMonthDay(startTime);
      }
    }
  }

  return defaultHeaderInfo;
};

export function UnitChartHeader(props: IProps) {
  const {
    type,
    data,
    mode,
    lastUpdatedTime,
    isPrintable,
    isCurrent,
    zoneId,
    communityUnitTimezone,
  } = props;

  /* Hooks */
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  /* Selectors */
  const pagination = useAppSelector(
    (state) => state.analyticsState.maximizedPagination
  );

  const maximized = mode === ChartMode.maximized;

  const chartIcon = getChartIcon(type);
  const chartTitle = getChartTitle(type);

  const categoryData = getHeaderInformation(
    type,
    data,
    mode,
    isPrintable,
    {
      pagination,
      lastUpdatedTime,
      isCurrent,
    },
    communityUnitTimezone
  );

  const handleExpand = (chartType: string) => {
    dispatch(expandAnalyticsChart({ chartType, zoneId }));
  };

  const handleMinimize = () => {
    dispatch(minimizeAnalyticsChart());
  };

  const enableOverlay = !isPrintable;
  return (
    <div className={classes.container}>
      <Grid container className={classes.chartHeader}>
        <Grid item xs={2} md={3} className={classes.chartDetails}>
          <div className={classes.detailContainedLeft}>
            <span>{categoryData.valueDetail.date}</span>
            <span
              className={clsx(
                classes.category,
                categoryData.category === "" && classes.noVisible,
                classes[categoryData.color]
              )}
            >
              {categoryData.category !== ""
                ? t(categoryData.category).toUpperCase()
                : "placeholder"}
            </span>
          </div>
        </Grid>
        <Grid item xs={8} md={6}>
          <div className={classes.chartTitle}> {t(chartTitle)} </div>
          <img
            src={chartIcon}
            alt={t("alt_text_bathroom_visits")}
            draggable={false}
            className={classes.chartIcon}
          />
        </Grid>
        <Grid item xs={2} md={3}>
          <div
            className={clsx(
              classes.detailContainedRight,
              classes[categoryData.color]
            )}
          >
            <span className={classes.valueFormat}>
              {categoryData.valueDetail.value}
            </span>
            <span
              className={clsx(
                classes.unitFormat,
                categoryData.valueDetail.unit === "" && classes.noVisible
              )}
            >
              {categoryData.valueDetail.unit !== ""
                ? capitalize(categoryData.valueDetail.unit)
                : "placeholder"}
            </span>
          </div>
        </Grid>
      </Grid>
      <Divider classes={{ root: classes.divider }} light />
      {enableOverlay && (
        <div
          className={classes.overlay}
          onClick={() => (!maximized ? handleExpand(type) : handleMinimize())}
        >
          <div className={classes.content}>
            {maximized ? (
              <IconButton
                classes={{
                  root: classes.iconButtonRoot,
                }}
                disableRipple={true}
                disableFocusRipple={true}
                aria-label="expand"
              >
                <img
                  src={AnalyticsIcons.MinimizeChart}
                  alt={t("alt_text_minimize_chart", { chart_type: type })}
                  draggable={false}
                  className={classes.toggleSizeIcon}
                />
              </IconButton>
            ) : (
              <IconButton
                classes={{
                  root: classes.iconButtonRoot,
                }}
                disableRipple={true}
                disableFocusRipple={true}
                aria-label="minimize"
              >
                <img
                  src={AnalyticsIcons.MaximizeChart}
                  alt={t("alt_text_maximize_chart", { chart_type: type })}
                  draggable={false}
                  className={classes.toggleSizeIcon}
                />
              </IconButton>
            )}
            <div className={classes.text}>
              {maximized ? t("exit_full_screen") : t("expand_full_screen")}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

interface IProps {
  type: string;
  data: any;
  mode: ChartMode;
  lastUpdatedTime?: string;
  isPrintable: boolean;
  isCurrent?: boolean;
  zoneId?: string;
  communityUnitTimezone?: string;
}
