import React from "react";
import { NotificationTypeFilter } from "./NotificationTypeFilter";
import { ResponderFilter } from "./ResponderFilter";
import { UnitFilter } from "./UnitFilter";

export function FilterPicker(props: {
  handleCloseFilter: () => void;
  handleResetSort: () => void;
  selectedColumn: string;
}) {
  const { handleCloseFilter, handleResetSort, selectedColumn } = props;

  const pickFilter = () => {
    switch (selectedColumn) {
      case "notification":
        return <NotificationTypeFilter handleCloseFilter={handleCloseFilter} />;
      case "unit":
        return (
          <UnitFilter filterID="unit" handleCloseFilter={handleCloseFilter} />
        );
      case "resident":
        return (
          <UnitFilter
            filterID="resident"
            handleCloseFilter={handleCloseFilter}
          />
        );
      case "responder":
        return (
          <ResponderFilter
            handleCloseFilter={handleCloseFilter}
            handleResetSort={handleResetSort}
          />
        );
      default:
        return null;
    }
  };

  return pickFilter();
}
