import React from "react";
import { Bar, defaults } from "react-chartjs-2";
import i18n from "../../i18n";
import ChartDataLabels, {
  Context as OptionsContext,
} from "chartjs-plugin-datalabels";
import { useTheme } from "@material-ui/core/styles";
import { setCustomPan } from "../analytics/analyticsSlice";
import { useAppDispatch } from "../app/appHooks";

defaults.font.family = "GothamRoundedBook";

export default function StackedBarChartC(props: IProps) {
  const {
    labels,
    valuesActvitiy,
    valuesBathroom,
    valuesDoor,
    valuesHelpFall,
    valuesTemperature,
    valuesTotals,
    max,
    animation,
    panEnabled,
    mappedMin,
    mappedMax,
    dateReference,
  } = props;

  /* Hooks */
  const theme = useTheme();
  const dispatch = useAppDispatch();

  let limits = undefined;
  if (!!panEnabled && dateReference) {
    limits = { x: { min: 0, max: dateReference.length - 1 } };
  }

  const hideValuePivot = max / 10;
  const options = {
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    responsive: true,
    maintainAspectRatio: false,
    animation: animation !== undefined ? animation : true,
    plugins: {
      legend: {
        display: true,
        align: "center",
        position: "bottom",
      },
      title: {
        display: false,
      },
      datalabels: {
        display: false,
        anchor: "end",
        align: "bottom",
        font: {
          family: "GothamRoundedBook",
        },
      },
      zoom: {
        limits: limits,
        pan: {
          enabled: !!panEnabled,
          mode: "x",
          onPanComplete: ({ chart }: { chart: any }) => {
            if (dateReference) {
              let localStart = dateReference[chart.scales["x"].min];
              let localEnd = dateReference[chart.scales["x"].max];

              dispatch(
                setCustomPan({
                  localStart: localStart,
                  localEnd: localEnd,
                })
              );
            }
          },
        },
      },
    },
    scales: {
      x: {
        type: "category",
        stacked: true,
        min: mappedMin,
        max: mappedMax,
      },
      y: {
        type: "linear",
        suggestedMax: max,
        position: "left",
        ticks: {
          stepSize: 4,
        },
      },
      y1: {
        type: "linear",
        position: "right",
        ticks: {
          display: true,
          callback: () => "",
        },
        grid: {
          display: false,
        },
      },
    },
  };

  const displayBarLabels = true;

  const data = (canvas: HTMLCanvasElement) => {
    return {
      labels: labels,
      datasets: [
        {
          stack: "key",
          label: i18n.t("activity"),
          data: valuesActvitiy,
          backgroundColor: theme.palette.categoryActivity.main,
          barThickness: 30,
          datalabels: {
            display: displayBarLabels,
            color: "white",
            formatter: (value: number) => {
              return value > hideValuePivot ? value : "";
            },
          },
        },
        {
          stack: "key",
          label: i18n.t("bathroom"),
          data: valuesBathroom,
          backgroundColor: theme.palette.categoryBathroom.main,
          barThickness: 30,
          datalabels: {
            display: displayBarLabels,
            color: "white",
            formatter: (value: number) => {
              return value > hideValuePivot ? value : "";
            },
          },
        },
        {
          stack: "key",
          label: i18n.t("door"),
          data: valuesDoor,
          backgroundColor: theme.palette.categoryDoor.main,
          barThickness: 30,
          datalabels: {
            color: "white",
            display: displayBarLabels,
            formatter: (value: number) => {
              return value > hideValuePivot ? value : "";
            },
          },
        },
        {
          stack: "key",
          label: i18n.t("temperature"),
          data: valuesTemperature,
          backgroundColor: theme.palette.categoryTemperature.main,
          barThickness: 30,
          datalabels: {
            display: displayBarLabels,
            color: "white",
            formatter: (value: number) => {
              return value > hideValuePivot ? value : "";
            },
          },
        },
        {
          stack: "key",
          label: i18n.t("help_fall"),
          data: valuesHelpFall,
          backgroundColor: theme.palette.categoryHelpFall.main,
          barThickness: 30,
          datalabels: {
            labels: {
              value: {
                color: "white",
                display: displayBarLabels,
                align: "start",
                formatter: (value: number) => {
                  return value > hideValuePivot ? value : "";
                },
              },
              totals: {
                display: true,
                font: {
                  family: theme.typography.tertiaryFontFamily,
                },
                color: "black",
                align: "top",
                formatter: (index: number, context: OptionsContext) => {
                  return valuesTotals[context.dataIndex];
                },
              },
              align: "top",
              padding: {
                bottom: "-6",
              },
            },
          },
          //   datalabels: {
          //     display: displayBarLabels,
          //     color: "white",
          //     formatter: (value: number) => {
          //       return value > hideValuePivot ? value : "";
          //     },
          //   },
        },
      ],
    };
  };

  // Update chart when fonts are loaded to prevent need to hover for fonts to appear correctly
  const chartRef = React.useRef();
  document.fonts.ready.then(() => {
    if (chartRef.current) {
      (chartRef.current as any)?.update();
    }
  });

  return (
    <Bar
      type="horizontalBar"
      data={data}
      ref={chartRef}
      options={options}
      plugins={[ChartDataLabels]}
      width={100}
      height={40}
    />
  );
}

interface IProps {
  labels: (string | string[])[];
  max: number;
  valuesActvitiy: number[];
  valuesBathroom: number[];
  valuesDoor: number[];
  valuesHelpFall: number[];
  valuesTemperature: number[];
  valuesTotals: number[];
  values?: IStackedBarDataFormat;
  animation?: boolean;
  mappedMin?: number;
  mappedMax?: number;
  dateReference?: string[];
  panEnabled?: boolean;
}

export interface IStackedBarDataFormat {
  visitCount: number;
  isOutlier: boolean;
  stack: string;
  label: string;
  data: number[];
  backgroundColor: CanvasGradient | string;
  barThickness: number;
  datalabels: {
    color: string;
  };
}
