import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AnalyticsChartTypes, ReducerStates } from "../../helpers/constants";
import { DateTime } from "luxon";
import { IUnit } from "../../services/dashboard.services";
import { sortUnits, isHelpAtHome } from "../dashboard/dashboardSlice";
import { changeSelectedCommunity } from "../header/headerSlice";
import {
  IBathroomEventArray,
  IBathroomEventData,
  ISleepData,
  ITemperatureData,
  IUnitActivityData,
  IUnitKitchenData,
  IUnitNotificationData,
} from "../dashboard/eventDataTypes";
import { logout } from "../login/loginSlice";
import {
  expandAnalyticsChart,
  getChartDataByDate,
  getChartDataLeft,
  getChartDataUp,
  getChartDataRight,
  getChartDataDown,
  getUnitChartData,
  toggleUnitWatchFlag,
} from "./analyticsThunks";
import { IFailedRequest } from "../../services/login.services";

export interface IUnitChartData {
  notification: IUnitNotificationData;
  sleep: ISleepData[];
  bathroom: IBathroomEventArray[];
  activity: IUnitActivityData;
  temperature: ITemperatureData;
  kitchen: IUnitKitchenData;
}

export interface IAnalyticsState {
  units?: IUnit[];
  selectedUnit?: string;
  selectedUnitData?: IUnitChartData;
  chartDataLastUpdated?: string;
  chartDataMaximizedLastUpdated?: string;
  lastVisitedTime?: string;
  maximizeChart?: { type: string; data: any };
  maximizedPagination?: {
    start: string;
    end: string;
    localStart: string;
    localEnd: string;
    zoom: ZoomValues;
    customZoom?: number;
    currentTime: string;
  };
  chartCardHeight?: { viewerHeight: number; cardHeight: number };
  bathroomZoneId?: string;
  state: ReducerStates;
  firebaseState: ReducerStates;
  errorCode: string;
  errorMessage?: string;
}

export const initialAnalyticsState: IAnalyticsState = {
  units: undefined,
  selectedUnit: undefined,
  selectedUnitData: undefined,
  chartDataLastUpdated: undefined,
  chartDataMaximizedLastUpdated: undefined,
  lastVisitedTime: undefined,
  maximizeChart: undefined,
  maximizedPagination: undefined,
  chartCardHeight: undefined,
  bathroomZoneId: undefined,
  state: ReducerStates.IDLE,
  firebaseState: ReducerStates.IDLE,
  errorCode: "",
  errorMessage: "",
};
export const zoomValues = [1, 2, 6, 12, 24, 48] as const;
const DefaultZoomValue = zoomValues[4];
export type ZoomValues = typeof zoomValues[number];

export const zoomDials = (current: ZoomValues) => {
  const currIndex = zoomValues.findIndex((dial) => dial === current);
  const validPrev = currIndex !== -1 && currIndex !== 0;

  const validNext = currIndex !== -1 && currIndex + 1 < zoomValues.length;
  return {
    getNextZoomDial: () => (validNext ? zoomValues[currIndex + 1] : null),
    getPrevZoomDial: () => (validPrev ? zoomValues[currIndex - 1] : null),
    zoomOutDiff: () =>
      validNext ? (zoomValues[currIndex + 1] - current) / 2 : 0,
    zoomInDiff: () =>
      validPrev ? (current - zoomValues[currIndex - 1]) / 2 : 0,
  };
};

const closestZoomVal = (customZoom: number, mode: "in" | "out") => {
  return zoomValues.reduce(
    (prev, curr) => {
      if (mode === "in") {
        return Math.abs(curr - customZoom) < Math.abs(prev - customZoom)
          ? curr <= customZoom
            ? curr
            : prev
          : prev;
      } else {
        return Math.abs(curr - customZoom) < Math.abs(prev - customZoom)
          ? curr >= customZoom
            ? curr
            : prev
          : prev;
      }
    },
    mode === "in" ? zoomValues[0] : zoomValues[zoomValues.length - 1]
  );
};

const updateTemperatureChartData = (
  currentData: ITemperatureData,
  newData: ITemperatureData
): ITemperatureData => {
  const copyData = JSON.parse(JSON.stringify(currentData)) as ITemperatureData;
  const chartData = copyData.chart_data;
  if (newData.chart_data) {
    newData.chart_data.forEach((zone) => {
      const currZone = chartData.find((oldZone) => oldZone.name === zone.name);
      if (currZone !== undefined) {
        const concatArr = currZone.temperature_data.concat(
          zone.temperature_data
        );
        currZone.temperature_data = concatArr.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.timestamp === value.timestamp)
        );
      } else {
        chartData.push({
          name: zone.name,
          temperature_data: zone.temperature_data,
        });
      }
    });
  }
  return copyData;
};

const updateSleepChartData = (
  currentData: ISleepData[],
  newData: ISleepData[]
): ISleepData[] => {
  const copyData = JSON.parse(JSON.stringify(currentData)) as ISleepData[];

  return copyData.concat(newData);
};
const updateBathroomChartData = (
  currentData: IBathroomEventArray[],
  newData: IBathroomEventArray[]
): IBathroomEventArray[] => {
  const copyData = JSON.parse(
    JSON.stringify(currentData)
  ) as IBathroomEventArray[];

  return copyData.map((oldZone) => {
    const currZone = newData.find(
      (newZone) => newZone.zone_id === oldZone.zone_id
    );
    if (currZone !== undefined) {
      const newEvents: IBathroomEventData[] = [];
      currZone.data.forEach((bathroomEvent) => {
        const oldBathroomEvent = oldZone.data.find(
          (item) => item.date === bathroomEvent.date
        );
        if (oldBathroomEvent === undefined) {
          newEvents.push(bathroomEvent);
        }
      });

      oldZone.data = oldZone.data.concat(newEvents);
    }
    return oldZone;
  });
};

const updateKitchenChartData = (
  currentData: IUnitKitchenData,
  newData: IUnitKitchenData
): IUnitKitchenData => {
  const copyData = JSON.parse(JSON.stringify(currentData)) as IUnitKitchenData;

  if (copyData.weekly_count) {
    const currData = copyData.weekly_count;
    if (newData.weekly_count) {
      newData.weekly_count.forEach((item) => {
        const findDate = currData.find(
          (currItem) => currItem.date === item.date
        );
        if (findDate === undefined) {
          currData.push(item);
        }
      });
    }
  } else {
    if (newData.weekly_count) {
      copyData.weekly_count = newData.weekly_count;
    }
  }
  return copyData;
};

const updateNotificationChartData = (
  currentData: IUnitNotificationData,
  newData: IUnitNotificationData
): IUnitNotificationData => {
  const copyData = JSON.parse(
    JSON.stringify(currentData)
  ) as IUnitNotificationData;

  if (copyData.weekly_count) {
    const currData = copyData.weekly_count;
    if (newData.weekly_count) {
      newData.weekly_count.forEach((item) => {
        const findDate = currData.find(
          (currItem) => currItem.date === item.date
        );
        if (findDate === undefined) {
          currData.push(item);
        }
      });
    }
  } else {
    if (newData.weekly_count) {
      copyData.weekly_count = newData.weekly_count;
    }
  }
  return copyData;
};

const updateDataChart = (chartType: string, currentData: any, newData: any) => {
  if (chartType === AnalyticsChartTypes.activity) {
    return newData;
  } else if (chartType === AnalyticsChartTypes.temperature) {
    return updateTemperatureChartData(
      currentData as ITemperatureData,
      newData as ITemperatureData
    );
  } else if (chartType === AnalyticsChartTypes.sleep) {
    return updateSleepChartData(
      currentData as ISleepData[],
      newData as ISleepData[]
    );
  } else if (chartType === AnalyticsChartTypes.bathroom) {
    return updateBathroomChartData(
      currentData as IBathroomEventArray[],
      newData as IBathroomEventArray[]
    );
  } else if (chartType === AnalyticsChartTypes.kitchen) {
    return updateKitchenChartData(
      currentData as IUnitKitchenData,
      newData as IUnitKitchenData
    );
  } else if (chartType === AnalyticsChartTypes.notification) {
    return updateNotificationChartData(
      currentData as IUnitNotificationData,
      newData as IUnitNotificationData
    );
  }
};

const analyticsAsyncThunks = [
  toggleUnitWatchFlag,
  expandAnalyticsChart,
  getChartDataLeft,
  getUnitChartData,
  getChartDataByDate,
  getChartDataRight,
  getChartDataDown,
  getChartDataUp,
] as const;

// Check the state for every async thunk
const isAPendingAction = isPending(...analyticsAsyncThunks);

const isAFulfilledAction = isFulfilled(...analyticsAsyncThunks);

const isARejectedAction = isRejected(...analyticsAsyncThunks);

const analyticsSlice = createSlice({
  name: "analytics",
  initialState: initialAnalyticsState,
  reducers: {
    setSelectedUnit(state, action: PayloadAction<string>) {
      state.selectedUnit = action.payload;
      state.maximizeChart = initialAnalyticsState.maximizeChart;
      state.maximizedPagination = initialAnalyticsState.maximizedPagination;
      state.chartDataMaximizedLastUpdated =
        initialAnalyticsState.chartDataMaximizedLastUpdated;
    },
    loadCommunityUnits(state, action: PayloadAction<IUnit[]>) {
      const units: IUnit[] = action.payload;
      let tempUnits: IUnit[] = [];
      // sort communities by unit number
      if (units && units.length > 0) {
        const filteredUnits = units.filter(
          (unit) =>
            (unit.gateway_id && !isHelpAtHome(unit)) ||
            (unit.gateway_id &&
              isHelpAtHome(unit) &&
              unit.residents !== undefined &&
              unit.residents !== null &&
              unit.residents.length > 0)
        );
        tempUnits = sortUnits(filteredUnits);
      }
      state.units = tempUnits;
      state.firebaseState = ReducerStates.SUCCEEDED;
    },
    setAnalyticsLeaveTime(state, action: PayloadAction<string>) {
      state.lastVisitedTime = action.payload;
    },
    initAnalytics(state) {
      state.units = initialAnalyticsState.units;
      state.selectedUnit = initialAnalyticsState.selectedUnit;
      state.selectedUnitData = initialAnalyticsState.selectedUnitData;
      state.lastVisitedTime = initialAnalyticsState.lastVisitedTime;
    },
    loadFirebaseAnalytics(state) {
      state.firebaseState = ReducerStates.PENDING;
    },
    failedFirebaseAnalytics(state) {
      state.firebaseState = ReducerStates.FAILED;
    },
    minimizeAnalyticsChart(state) {
      state.maximizeChart = initialAnalyticsState.maximizeChart;
      state.maximizedPagination = initialAnalyticsState.maximizedPagination;
      state.chartDataMaximizedLastUpdated =
        initialAnalyticsState.chartDataMaximizedLastUpdated;
      state.bathroomZoneId = initialAnalyticsState.bathroomZoneId;
    },
    panChart(
      state,
      action: PayloadAction<{ startTime: string; endTime: string }>
    ) {
      if (state.maximizedPagination) {
        state.maximizedPagination = {
          ...state.maximizedPagination,
          localStart: action.payload.startTime,
          localEnd: action.payload.endTime,
        };
      }
    },
    chartZoomIn(state, action: PayloadAction<ZoomValues>) {
      if (state.maximizedPagination && state.maximizeChart) {
        const pagination = state.maximizedPagination;
        const nextZoom = action.payload;
        const customZoom = state.maximizedPagination.customZoom;

        let diffToNext = zoomDials(state.maximizedPagination.zoom).zoomInDiff();

        let targetZoom =
          customZoom !== undefined
            ? closestZoomVal(customZoom, "in")
            : nextZoom;
        if (customZoom !== undefined) {
          diffToNext = Math.abs(customZoom - targetZoom) / 2;
        }

        let startTime = DateTime.fromISO(pagination.localStart, {
          zone: "utc",
        }).plus({ hours: diffToNext });

        let endTime = DateTime.fromISO(pagination.localEnd, {
          zone: "utc",
        }).minus({ hours: diffToNext });

        let dataStart = DateTime.fromISO(pagination.start, {
          zone: "utc",
        });
        if (startTime < dataStart) {
          startTime = dataStart;
          endTime = startTime.plus({ hours: targetZoom });
        }

        state.maximizedPagination = {
          ...state.maximizedPagination,
          localStart: startTime.toISO(),
          localEnd: endTime.toISO(),
          zoom: targetZoom,
          customZoom: undefined,
        };
      }
    },
    chartZoomOut(state, action: PayloadAction<ZoomValues>) {
      if (state.maximizedPagination && state.maximizeChart) {
        const pagination = state.maximizedPagination;
        const nextZoom = action.payload;
        const customZoom = state.maximizedPagination.customZoom;

        let diffToNext = zoomDials(
          state.maximizedPagination.zoom
        ).zoomOutDiff();

        let targetZoom =
          customZoom !== undefined
            ? closestZoomVal(customZoom, "out")
            : nextZoom;
        if (customZoom !== undefined) {
          diffToNext = Math.abs(customZoom - targetZoom) / 2;
        }

        let startTime = DateTime.fromISO(pagination.localStart, {
          zone: "utc",
        }).minus({ hours: diffToNext });

        let endTime = DateTime.fromISO(pagination.localEnd, {
          zone: "utc",
        }).plus({ hours: diffToNext });

        //check if endTime is greater than last updated time, if it is,
        //then end time is last updated and start Time is end time - zoom

        const dataEnd = DateTime.fromISO(pagination.end, {
          zone: "utc",
        });
        if (endTime > dataEnd) {
          endTime = dataEnd;
          startTime = endTime.minus({ hours: targetZoom });
        }

        state.maximizedPagination = {
          ...state.maximizedPagination,
          localStart: startTime.toISO(),
          localEnd: endTime.toISO(),
          zoom: targetZoom,
          customZoom: undefined,
        };
      }
    },
    setAnalyticsCardHeight(state, action) {
      state.chartCardHeight = action.payload;
    },
    setCustomZoom(
      state,
      action: PayloadAction<{
        localStart: string;
        localEnd: string;
        customZoom: number;
      }>
    ) {
      const { localStart, localEnd, customZoom } = action.payload;

      if (state.maximizedPagination) {
        state.maximizedPagination = {
          ...state.maximizedPagination,
          localStart: localStart,
          localEnd: localEnd,
          customZoom: customZoom,
        };
      }
    },
    setCustomPan(
      state,
      action: PayloadAction<{
        localStart: string;
        localEnd: string;
      }>
    ) {
      const { localStart, localEnd } = action.payload;
      if (state.maximizedPagination) {
        state.maximizedPagination = {
          ...state.maximizedPagination,
          localStart: localStart,
          localEnd: localEnd,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUnitChartData.fulfilled, (state, action) => {
        state.selectedUnitData = action.payload;
        state.chartDataLastUpdated = DateTime.now().toUTC().toISO();
      })
      .addCase(changeSelectedCommunity, (state) => {
        state.state = ReducerStates.IDLE;
        state.units = initialAnalyticsState.units;
        state.selectedUnit = initialAnalyticsState.selectedUnit;
        state.selectedUnitData = initialAnalyticsState.selectedUnitData;
        state.lastVisitedTime = initialAnalyticsState.lastVisitedTime;
        state.chartDataLastUpdated = initialAnalyticsState.chartDataLastUpdated;
        state.maximizeChart = initialAnalyticsState.maximizeChart;
        state.maximizedPagination = initialAnalyticsState.maximizedPagination;
        state.chartDataMaximizedLastUpdated =
          initialAnalyticsState.chartDataMaximizedLastUpdated;
        state.bathroomZoneId = initialAnalyticsState.bathroomZoneId;
      })
      .addCase(toggleUnitWatchFlag.fulfilled, (state, action) => {
        const newUnit: IUnit = action.payload;
        const unitIndex = state.units?.findIndex(
          (unit) => unit.id === newUnit.id
        );
        if (
          state.units !== undefined &&
          unitIndex !== -1 &&
          unitIndex !== undefined
        ) {
          state.units[unitIndex].is_watched = newUnit.is_watched;
        }
      })
      .addCase(expandAnalyticsChart.fulfilled, (state, action) => {
        const chartType = action.meta.arg.chartType;
        if (action.payload !== null) {
          state.chartDataMaximizedLastUpdated = action.payload?.endTime;
          state.maximizedPagination = {
            start: action.payload.startTime,
            end: action.payload.endTime,
            localStart: action.payload.localStart,
            localEnd: action.payload.localEnd,
            currentTime: action.payload.currentTime,
            zoom: (action.payload.zoom as ZoomValues) ?? DefaultZoomValue,
          };

          state.maximizeChart = {
            type: chartType,
            data: action.payload.data,
          };
        } else {
          state.maximizeChart = initialAnalyticsState.maximizeChart;
          state.bathroomZoneId = initialAnalyticsState.bathroomZoneId;
        }

        if (action.meta.arg.zoneId) {
          state.bathroomZoneId = action.meta.arg.zoneId;
        }
      })
      .addCase(getChartDataLeft.fulfilled, (state, action) => {
        const args = action.meta.arg;
        if (action.payload) {
          if (
            state.maximizeChart &&
            (args.chartType === AnalyticsChartTypes.activity ||
              args.chartType === AnalyticsChartTypes.temperature)
          ) {
            state.maximizeChart.data = updateDataChart(
              args.chartType,
              state.maximizeChart.data,
              action.payload.data
            );
          } else {
            state.maximizeChart = {
              type: args.chartType,
              data: action.payload,
            };
          }

          if (state.maximizedPagination) {
            if (action.payload.newStartTime) {
              state.maximizedPagination = {
                ...state.maximizedPagination,
                start: action.payload.newStartTime,
                localStart: args.localStartTime,
                localEnd: args.localEndTime,
              };
              if (action.payload.newEndTime !== undefined) {
                state.maximizedPagination = {
                  ...state.maximizedPagination,
                  end: action.payload.newEndTime,
                };
              }
            } else {
              state.maximizedPagination = {
                ...state.maximizedPagination,
                start: args.localStartTime,
                end: args.localEndTime,
              };
            }
          }
        }
      })
      .addCase(getChartDataUp.fulfilled, (state, action) => {
        const args = action.meta.arg;
        if (action.payload) {
          if (action.payload.data) {
            if (state.maximizeChart) {
              state.maximizeChart.data = updateDataChart(
                args.chartType,
                state.maximizeChart.data,
                action.payload.data
              );
            } else {
              state.maximizeChart = {
                type: args.chartType,
                data: action.payload,
              };
            }
          }
          if (state.maximizedPagination && action.payload) {
            state.maximizedPagination = {
              ...state.maximizedPagination,
              end: action.payload.end,
              localStart: action.payload.localStart,
              localEnd: action.payload.localEnd,
            };
          }
        }
      })
      .addCase(getChartDataRight.fulfilled, (state, action) => {
        const args = action.meta.arg;
        if (action.payload) {
          if (action.payload.data) {
            if (
              state.maximizeChart &&
              (args.chartType === AnalyticsChartTypes.activity ||
                args.chartType === AnalyticsChartTypes.temperature)
            ) {
              state.maximizeChart.data = updateDataChart(
                args.chartType,
                state.maximizeChart.data,
                action.payload.data
              );
            } else {
              state.maximizeChart = {
                type: args.chartType,
                data: action.payload,
              };
            }
          }
          if (state.maximizedPagination && action.payload) {
            state.maximizedPagination = {
              ...state.maximizedPagination,
              end: action.payload.end,
              localStart: action.payload.localStart,
              localEnd: action.payload.localEnd,
            };
            if (action.payload.start !== undefined) {
              state.maximizedPagination = {
                ...state.maximizedPagination,
                start: action.payload.start,
              };
            }
          }
        }
      })
      .addCase(getChartDataDown.fulfilled, (state, action) => {
        const args = action.meta.arg;
        if (action.payload) {
          if (action.payload.data) {
            if (state.maximizeChart) {
              state.maximizeChart.data = updateDataChart(
                args.chartType,
                state.maximizeChart.data,
                action.payload.data
              );
            } else {
              state.maximizeChart = {
                type: args.chartType,
                data: action.payload,
              };
            }
          }
          if (state.maximizedPagination) {
            if (action.payload.newStartTime) {
              state.maximizedPagination = {
                ...state.maximizedPagination,
                start: action.payload.newStartTime,
                localStart: args.localStartTime,
                localEnd: args.localEndTime,
              };
            } else {
              state.maximizedPagination = {
                ...state.maximizedPagination,
                start: args.localStartTime,
                end: args.localEndTime,
              };
            }
          }
        }
      })
      .addCase(getChartDataByDate.fulfilled, (state, action) => {
        if (action.payload) {
          if (state.maximizeChart) {
            state.maximizeChart.data = action.payload.data;
          }

          if (state.maximizedPagination) {
            state.maximizedPagination = {
              ...state.maximizedPagination,
              start: action.payload.start,
              end: action.payload.end,
              localStart: action.payload.localStart,
              localEnd: action.payload.localEnd,
              zoom: state.maximizedPagination.zoom,
            };
          }
        }
      })
      .addCase(logout.type, (state) => {
        return initialAnalyticsState;
      })

      .addMatcher(isAPendingAction, (state) => {
        state.state = ReducerStates.PENDING;
        state.errorCode = initialAnalyticsState.errorCode;
        state.errorMessage = initialAnalyticsState.errorMessage;
      })
      .addMatcher(isAFulfilledAction, (state) => {
        state.state = ReducerStates.SUCCEEDED;
      })
      .addMatcher(isARejectedAction, (state, action) => {
        if (action.meta.rejectedWithValue) {
          const error = (action.payload as unknown) as IFailedRequest;

          state.errorCode = error?.code || "";
          state.errorMessage = error?.message || "";
        } else {
          state.errorMessage = action.error.message;
        }
        state.state = ReducerStates.FAILED;
      });
  },
});

const { reducer } = analyticsSlice;

export const {
  setSelectedUnit,
  loadCommunityUnits,
  setAnalyticsLeaveTime,
  initAnalytics,
  loadFirebaseAnalytics,
  failedFirebaseAnalytics,
  minimizeAnalyticsChart,
  panChart,
  setAnalyticsCardHeight,
  chartZoomIn,
  chartZoomOut,
  setCustomZoom,
  setCustomPan,
} = analyticsSlice.actions;

export default reducer;
