import {
  createTheme,
  ThemeOptions,
  responsiveFontSizes,
} from "@material-ui/core/styles";
import { appTheme, getTandemTheme } from "../helpers/constants";

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    dark: Palette["primary"];
    light: Palette["primary"];
    normal: Palette["primary"];
    gradientDay: Palette["primary"];
    gradientNight: Palette["primary"];
    gradientMorning: Palette["primary"];
    gradientAfternoon: Palette["primary"];
    gradientEvening: Palette["primary"];
    activityMotion: Palette["primary"];
    contactBlue: Palette["primary"];
    paused: Palette["primary"];
    noData: Palette["primary"];
    metrics: Palette["primary"];
    indicator: Palette["primary"];
    gray: Palette["primary"];
    lightGrey: Palette["primary"];
    categoryActivity: Palette["primary"];
    categoryBathroom: Palette["primary"];
    categoryDoor: Palette["primary"];
    categoryHelpFall: Palette["primary"];
    categoryTemperature: Palette["primary"];
  }
  interface PaletteOptions {
    dark: PaletteOptions["primary"];
    light: PaletteOptions["primary"];
    normal: PaletteOptions["primary"];
    gradientDay: PaletteOptions["primary"];
    gradientNight: PaletteOptions["primary"];
    gradientMorning: PaletteOptions["primary"];
    gradientAfternoon: PaletteOptions["primary"];
    gradientEvening: PaletteOptions["primary"];
    contactBlue: PaletteOptions["primary"];
    activityMotion: PaletteOptions["primary"];
    paused: PaletteOptions["primary"];
    noData: PaletteOptions["primary"];
    metrics: PaletteOptions["primary"];
    indicator: PaletteOptions["primary"];
    gray: PaletteOptions["primary"];
    lightGrey: PaletteOptions["primary"];
  }
}

declare module "@material-ui/core/styles/createTypography" {
  interface Typography {
    primaryFontFamily: string;
    secondaryFontFamily: string;
    tertiaryFontFamily: string;
  }
}

declare module "@material-ui/core/styles/createPalette" {
  interface PaletteColor {
    trans?: string;
    lightTrans?: string;
    anomaly?: string;
  }
}

export const getTheme = () => {
  const isFrame = window.self !== window.top;
  const parentDomain = document.referrer;
  if (isFrame && parentDomain !== "") {
    return getThemeByDomain(parentDomain) ?? appTheme;
  } else {
    return appTheme as ThemeOptions;
  }
};

const getThemeByDomain = (parentDomain: string) => {
  const themeByDomain: { [index: string]: any } = {
    "http://localhost:5001/": getTandemTheme(),
    "https://localhost:5001/": getTandemTheme(),
    "https://portal-wrapper-dot-care-api-staging.appspot.com/": getTandemTheme(),
    "https://d3fck328fy4ivx.cloudfront.net/": getTandemTheme(),
    "https://caregiverapp-dev.tandemcommunities.com/": getTandemTheme(),
    "https://caregiverapp.tandemcommunities.com/": getTandemTheme(),
    "https://caregiverappdev.tandemcommunities.com/": getTandemTheme(),
  };
  return themeByDomain[parentDomain];
};

export default function createAppTheme() {
  return responsiveFontSizes(createTheme(getTheme()));
}
