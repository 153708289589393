import { useEffect, useRef } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../app/appHooks";
import {
  failedFirebaseAnalytics,
  initAnalytics,
  loadCommunityUnits,
  setAnalyticsLeaveTime,
} from "./analyticsSlice";
import { UnitList } from "./UnitList";
import { AnalyticsViewer } from "./AnalyticsViewer";
import { Prompt } from "react-router-dom";
import { Views } from "../../helpers/constants";
import { DateTimeISO, DateTimeNow } from "../../helpers/datetime";
import { FloatingNotificationPanel } from "../common/FloatingNotificationPanel";

import { AnalyticsIcons } from "../../helpers/iconImports";
import { minToolbarHeight } from "../app/App";
import { loadUnitsFromFirebase } from "../../helpers/utils";

const useStyles = makeStyles<Theme, Boolean>((theme: Theme) =>
  createStyles({
    analyticsContainer: {
      display: "flex",
      height: (showBanner) =>
        `calc(100vh - ${minToolbarHeight(showBanner, theme)})`,
    },
    unitListContainer: {
      display: "flex",
      flex: "0 0 300px",
      backgroundColor: theme.palette.light.light,
    },
    analyticsViewerContainer: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
      overflowY: "hidden",
      overflowX: "hidden",
      "@media (max-height: 830px)": {
        overflowY: "auto",
      },
      [theme.breakpoints.down("md")]: {
        overflowY: "auto",
      },
    },
    [`@media print`]: {
      unitListContainer: {
        visibility: "hidden",
      },
    },
  })
);

export default function Analytics() {
  const showBanner = useAppSelector((state) => state.headerState.showBanner);

  /* ---- Hooks ---- */
  const classes = useStyles(showBanner);
  const dispatch = useDispatch();

  /* ---- Selectors ---- */
  const firebaseAuth = useAppSelector((state) => state.loginState.firebaseAuth);
  const communityID = useAppSelector(
    (state) => state.headerState.selectedCommunity?.id
  );
  const lastVisitedTime = useAppSelector(
    (state) => state.analyticsState.lastVisitedTime
  );
  const user = useAppSelector((state) => state.headerState.user);
  const userUnits = useAppSelector((state) => state.headerState.units);
  /* ---- Refs ---- */
  const containerRef = useRef<HTMLDivElement | null>(null);

  /* ---- Effects ---- */
  useEffect(() => {
    return loadUnitsFromFirebase(
      firebaseAuth,
      user,
      communityID,
      dispatch,
      loadCommunityUnits,
      failedFirebaseAnalytics
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityID, firebaseAuth, user, user?.roles, userUnits]);

  useEffect(() => {
    if (lastVisitedTime !== undefined) {
      const currentTime = DateTimeNow();
      const lastVisitedDatetime = DateTimeISO(lastVisitedTime);
      const diff = currentTime.diff(lastVisitedDatetime, ["minutes"]);
      if (diff.minutes > 60) {
        dispatch(initAnalytics());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNavigateAwayFromAnalytics = () => {
    dispatch(setAnalyticsLeaveTime(DateTimeNow().toISO()));
  };
  useEffect(() => {
    //preload control images
    type analyticsKeys = keyof typeof AnalyticsIcons;
    Object.keys(AnalyticsIcons).forEach((picture) => {
      const img = new Image();
      img.src = AnalyticsIcons[picture as analyticsKeys];
    });
  }, []);

  return (
    <div className={classes.analyticsContainer}>
      <div className={classes.unitListContainer}>
        <UnitList />
      </div>
      <div
        className={classes.analyticsViewerContainer}
        ref={containerRef}
        id="viewer-container"
      >
        <AnalyticsViewer containerRef={containerRef} />
      </div>
      <FloatingNotificationPanel />
      <Prompt
        message={(location) => {
          if (location.pathname !== Views.ANALYTICS) {
            handleNavigateAwayFromAnalytics();
          }
          return true;
        }}
      />
    </div>
  );
}
