import React from "react";
import {
  INotificationData,
  IUnitNotificationData,
} from "../../dashboard/eventDataTypes";
import { AnalyticsChartTypes } from "../../../helpers/constants";
import { UnitChartHeader } from "./UnitChartHeader";
import AnalyticsChartContainer from "./AnalyticsChartContainer";
import { DateTime } from "luxon";
import { CHART_DATA_LIMIT_MINIMIZED } from "../AnalyticsViewer";
import { ChartMode } from "../AnalyticsViewerCharts";
import { timeConfigurations } from "../../../helpers/datetime";
import StackedBarChartC from "../../common/StackedBarChartC";

export function UnitNotificationChart(props: IProps) {
  const { chartData, mode, lastUpdatedTime, isPrintable, pagination } = props;

  const { locale } = timeConfigurations();

  const dereferencedData = JSON.parse(
    JSON.stringify(chartData.weekly_count || [])
  ) as INotificationData[];

  let data = dereferencedData.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );

  const filterWithPagination = (fullData: INotificationData[]) => {
    if (pagination) {
      const startTime = DateTime.fromISO(pagination.startTime).startOf("day");
      const endTime = DateTime.fromISO(pagination.endTime).endOf("day");
      return fullData.filter((item) => {
        const date = DateTime.fromISO(item.date);
        return date >= startTime && date <= endTime;
      });
    }
    return fullData;
  };

  const filteredData = filterWithPagination(dereferencedData);

  // Reference dates
  let dates = undefined;
  let mappedMin = undefined;
  let mappedMax = undefined;

  if (pagination && mode === ChartMode.maximized) {
    dates = data.map((item) => item.date);
    mappedMin = dates.indexOf(pagination.startTime);
    mappedMax = dates.indexOf(pagination.endTime);
  }

  data =
    mode === ChartMode.maximized
      ? data
      : data.slice(-CHART_DATA_LIMIT_MINIMIZED);

  const valuesActivity: number[] = [];
  const valuesBathroom: number[] = [];
  const valuesDoor: number[] = [];
  const valuesHelpFall: number[] = [];
  const valuesTemperature: number[] = [];
  const valuesTotals: number[] = [];
  const labels: (string | string[])[] = [];
  let max = 0;

  data.map((item, index) => {
    valuesActivity.push(item.activity);
    valuesBathroom.push(item.bathroom);
    valuesDoor.push(item.door);
    valuesHelpFall.push(item.help_fall);
    valuesTemperature.push(item.temperature);

    valuesTotals.push(
      item.activity +
        item.bathroom +
        item.door +
        item.help_fall +
        item.temperature
    );

    const dateObj = DateTime.fromISO(item.date).setLocale(locale);
    labels.push([dateObj.toFormat("EEE"), dateObj.toFormat("dd")]);
    max = Math.max(
      max,
      item.activity +
        item.bathroom +
        item.door +
        item.help_fall +
        item.temperature
    );

    return index;
  });

  max = Math.ceil((max + 2.5) / 5) * 5;

  const headerData = {
    weekly_count: filteredData,
  };

  return (
    <React.Fragment>
      <UnitChartHeader
        type={AnalyticsChartTypes.notification}
        mode={mode}
        data={headerData}
        isPrintable={isPrintable}
        lastUpdatedTime={lastUpdatedTime}
      />
      <AnalyticsChartContainer isPrintable={isPrintable}>
        <StackedBarChartC
          labels={labels}
          valuesActvitiy={valuesActivity}
          valuesBathroom={valuesBathroom}
          valuesDoor={valuesDoor}
          valuesHelpFall={valuesHelpFall}
          valuesTemperature={valuesTemperature}
          valuesTotals={valuesTotals}
          mappedMin={mappedMin}
          mappedMax={mappedMax}
          dateReference={dates}
          max={max}
          animation={false}
          panEnabled={mode === ChartMode.maximized}
        />
      </AnalyticsChartContainer>
    </React.Fragment>
  );
}

interface IProps {
  chartData: IUnitNotificationData;
  mode: ChartMode;
  lastUpdatedTime?: string;
  isPrintable: boolean;
  pagination?: { startTime: string; endTime: string };
}
