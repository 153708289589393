import React, { useEffect } from "react";
import Box from "@material-ui/core/Box/Box";
import Link from "@material-ui/core/Link/Link";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../helpers/store";
import { isSoftEvent, removeCommunityEvent } from "../dashboardSlice";
import NotificationEvent from "../NotificationEvent";
import { markAllWarningsRead } from "../dashboardSlice";
import {
  EVENT_CARD_HEIGHT,
  ReadStatus,
  TabTypes,
} from "../../../helpers/constants";
import { isPausedEvent } from "../CommunityUnit";
import "./index.css";
import { getFirebaseDBReference } from "../../../helpers/firebase";
import { IEvent } from "../../../services/dashboard.services";
import useOrganization from "./hooks/useOrganization";
import { VariableSizeList as List } from "react-window";

export default function WarningNotifications(props: IProps) {
  const { addTab, unitId, organization, events, height, width } = props;
  /* ---- Hooks ---- */
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedOrganizationID, orgsCommunities } = useOrganization();

  /* ---- Selectors ---- */
  const firebaseAuth = useSelector(
    (state: IAppState) => state.loginState.firebaseAuth
  );
  const community = useSelector(
    (state: IAppState) => state.headerState.selectedCommunity
  );
  const communityEvents = useSelector(
    (state: IAppState) => state.dashboardState.communityEvents
  );
  const currentUser = useSelector((state: IAppState) => state.headerState.user);

  const selectedCommunityID = useSelector(
    (state: IAppState) => state.headerState.selectedCommunity?.id
  );

  const isOrganization = useSelector(
    (state: IAppState) => !!state.headerState.selectedOrganization
  );

  let warningEvents =
    communityEvents &&
    communityEvents
      .filter((eventEl) => unitId === null || eventEl.unit_id === unitId)
      .filter((eventEl) => isSoftEvent(eventEl) && !isPausedEvent(eventEl))
      .filter((eventEl) => eventEl.read_status === ReadStatus.UNREAD);

  if (organization && events) {
    warningEvents = events.filter((eventEl) => isSoftEvent(eventEl));
  }

  /* ---- Effects ---- */
  useEffect(() => {
    if (
      selectedCommunityID !== undefined &&
      currentUser !== undefined &&
      firebaseAuth
    ) {
      const removedEventsRef = getFirebaseDBReference().ref(
        `communities/${selectedCommunityID}/unread_events_soft/${currentUser.id}`
      );
      const listener = removedEventsRef.on("child_removed", (snapshot) => {
        const resolvedEvent = snapshot.val();
        if (resolvedEvent === null) return;
        dispatch(removeCommunityEvent(resolvedEvent.id));
      });
      return () => removedEventsRef.off("child_removed", listener);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunityID, firebaseAuth, currentUser]);

  // Listens to removed childs from the communities unread soft events node and marks them as read
  useEffect(() => {
    if (
      selectedOrganizationID !== undefined &&
      orgsCommunities !== undefined &&
      currentUser !== undefined &&
      firebaseAuth
    ) {
      const removedEventsRefArr = orgsCommunities.map((community) =>
        getFirebaseDBReference().ref(
          `communities/${community.id}/unread_events_soft/${currentUser.id}`
        )
      );
      const listenerArr = removedEventsRefArr.map((communityRef) =>
        communityRef.on("child_removed", (snapshot) => {
          const resolvedEvent = snapshot.val();
          if (resolvedEvent === null) return;
          dispatch(removeCommunityEvent(resolvedEvent.id));
        })
      );
      return () =>
        removedEventsRefArr.forEach((communityRef, index) =>
          communityRef.off("child_removed", listenerArr[index])
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganizationID, firebaseAuth, currentUser]);

  /* ---- Methods ---- */
  const handleMarkAllWarningsRead = () => {
    if (community) {
      dispatch(markAllWarningsRead(community.id));
    }
  };

  const getItemSize = (index: number) => EVENT_CARD_HEIGHT + 5;

  const Row = <T extends { index: number; style: any }>(props: T) => {
    if (warningEvents === undefined) return null;
    const criticalEvent = warningEvents[props.index];
    return (
      <div style={props.style}>
        <NotificationEvent
          id={criticalEvent.id}
          unit_id={criticalEvent.unit_id}
          event_type={criticalEvent.event_type}
          responder_id={criticalEvent.responder_id}
          status={criticalEvent.status}
          data={criticalEvent.data}
          time_created={criticalEvent.time_created}
          notification_type={criticalEvent.notification_type}
          community_id={criticalEvent.community_id}
          read_status={criticalEvent.read_status}
          onClickOpenTab={addTab}
        />
      </div>
    );
  };
  return (
    <React.Fragment>
      {warningEvents && warningEvents.length > 0 && (
        <React.Fragment>
          {unitId == null && (
            <Box
              fontWeight="fontWeightBold"
              textAlign="right"
              fontSize="subtitle1.fontSize"
              my={1}
              style={{
                visibility: !isOrganization ? "visible" : "hidden",
              }}
            >
              <Link
                href="#"
                onClick={handleMarkAllWarningsRead}
                color="inherit"
                underline="always"
              >
                {t("mark_all_read")}
              </Link>
            </Box>
          )}
          <List
            height={height}
            itemCount={warningEvents?.length || 0}
            itemSize={getItemSize}
            width={width}
          >
            {Row}
          </List>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

interface IProps {
  addTab: (id: string, type: TabTypes) => void;
  unitId: string | null;
  organization?: boolean;
  events?: IEvent[];
  height: number;
  width: number;
}
