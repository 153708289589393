import { createAsyncThunk } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import {
  AnalyticsChartTypes,
  CHART_PAGINATION_LOAD,
  chartWeekWindow,
} from "../../helpers/constants";
import { analyticsServices } from "../../services/analytics.services";
import {
  IWatchedFlagData,
  dashboardServices,
} from "../../services/dashboard.services";
import { IAppState } from "../../helpers";
import { ZoomValues } from "./analyticsSlice";

// Toggles an unit watch flag
export const toggleUnitWatchFlag = createAsyncThunk(
  "analytics/toggleUnitWatchFlag",
  async (watchFlagData: IWatchedFlagData) => {
    const response = await dashboardServices.toggleWatchedFlag(watchFlagData);
    return response;
  }
);

const getMaximizedChartDataByType = async (data: {
  chartType: string;
  unitID: string;
  endTime?: DateTime;
  zoom?: ZoomValues;
  customZoom?: number;
}) => {
  const { chartType, unitID, endTime, zoom, customZoom } = data;
  const currentTime = DateTime.now().toUTC();
  const chartEndTime = endTime ? endTime : currentTime;

  if (chartType === AnalyticsChartTypes.sleep) {
    const sleepStartTime = chartEndTime.minus({ weeks: chartWeekWindow });
    return {
      startTime: sleepStartTime.toISODate(),
      endTime: chartEndTime.toISODate(),
      localStart: chartEndTime.minus({ weeks: 2 }).toISODate(),
      localEnd: chartEndTime.toISODate(),
      data: await analyticsServices.getUnitSleepData(unitID, {
        startDate: sleepStartTime.toISODate(),
        weeks: chartWeekWindow,
      }),
      // The zoom is usuallly in hours, but for sleep is in weeks
      zoom: 2,
      currentTime: currentTime.toISODate(),
    };
  }
  if (chartType === AnalyticsChartTypes.bathroom) {
    const bathroomStartTime = chartEndTime.minus({ weeks: chartWeekWindow });
    return {
      startTime: bathroomStartTime.toISODate(),
      endTime: chartEndTime.toISODate(),
      localStart: chartEndTime.minus({ weeks: 2 }).plus({ day: 1 }).toISODate(),
      localEnd: chartEndTime.toISODate(),
      data: await analyticsServices.getUnitBathroomData(unitID, {
        startDate: bathroomStartTime.toISODate(),
        weeks: chartWeekWindow,
      }),
      // The zoom is usually in hours, but for bathroom is in weeks
      zoom: 2,
      currentTime: currentTime.toISODate(),
    };
  }
  if (chartType === AnalyticsChartTypes.kitchen) {
    const kitchenStartTime = chartEndTime.minus({ weeks: chartWeekWindow });
    return {
      startTime: kitchenStartTime.toISODate(),
      endTime: chartEndTime.toISODate(),
      localStart: chartEndTime.minus({ weeks: 2 }).plus({ day: 1 }).toISODate(),
      localEnd: chartEndTime.toISODate(),
      data: await analyticsServices.getUnitKitchenActivityData(unitID, {
        startDate: kitchenStartTime.toISODate(),
        weeks: chartWeekWindow,
      }),
      // The zoom is usually in hours, but for bathroom is in weeks
      zoom: 2,
      currentTime: currentTime.toISODate(),
    };
  }

  if (chartType === AnalyticsChartTypes.notification) {
    const notificationStartTime = chartEndTime.minus({
      weeks: chartWeekWindow,
    });
    return {
      startTime: notificationStartTime.toISODate(),
      endTime: chartEndTime.toISODate(),
      localStart: chartEndTime.minus({ weeks: 2 }).toISODate(),
      localEnd: chartEndTime.toISODate(),
      data: await analyticsServices.getUnitNotificationData(unitID, {
        startDate: notificationStartTime.toISODate(),
        weeks: chartWeekWindow,
      }),
      zoom: 2,
      currentTime: currentTime.toISODate(),
    };
  }

  if (chartType === AnalyticsChartTypes.activity) {
    const activityStartTime = chartEndTime.minus({
      days: CHART_PAGINATION_LOAD,
    });

    // Return start time and end time to create pagination
    const chartZoom = customZoom ? customZoom : zoom ? zoom : 24;
    return {
      startTime: activityStartTime.toISO(),
      endTime: chartEndTime.toISO(),
      localStart: chartEndTime.minus({ hours: chartZoom }).toISO(),
      localEnd: chartEndTime.toISO(),
      data: await dashboardServices.getUnitActivityData(
        unitID,
        activityStartTime.toISO(),
        chartEndTime.toISO()
      ),
      zoom: chartZoom,
      currentTime: currentTime.toISO(),
    };
  }

  if (chartType === AnalyticsChartTypes.temperature) {
    const temperatureStartTime = chartEndTime.minus({
      days: CHART_PAGINATION_LOAD,
    });

    // Return start time and end time to create pagination
    const chartZoom = zoom ? zoom : 24;
    return {
      startTime: temperatureStartTime.toISO(),
      endTime: chartEndTime.toISO(),
      localStart: chartEndTime.minus({ hours: chartZoom }).toISO(),
      localEnd: chartEndTime.toISO(),
      data: await analyticsServices.getUnitTemperatureData(
        unitID,
        temperatureStartTime.toISO(),
        chartEndTime.toISO()
      ),
      zoom: chartZoom,
      currentTime: currentTime.toISO(),
    };
  }

  return null;
};

// Gets information to expand the analytics chart
export const expandAnalyticsChart = createAsyncThunk(
  "analytics/expandAnalyticsChart",
  async (
    {
      chartType,
      zoneId,
      endTime,
      customZoom,
    }: {
      chartType: string;
      zoneId?: string;
      endTime?: DateTime;
      customZoom?: number;
    },
    { getState }
  ) => {
    const appState = getState() as IAppState;
    const unitID = appState.analyticsState.selectedUnit;
    const zoom = appState.analyticsState.maximizedPagination?.zoom;
    if (unitID === undefined) return null;
    return getMaximizedChartDataByType({
      chartType,
      unitID,
      endTime,
      zoom,
      customZoom,
    });
  }
);

type GetChartData = {
  chartType: string;
  localStartTime: string;
  localEndTime: string;
};

export const getChartDataRight = createAsyncThunk(
  "analytics/getDataChartRight",
  async (getDataParams: GetChartData, { getState }) => {
    const appState = getState() as IAppState;
    const unitID = appState.analyticsState.selectedUnit;
    const pagination = appState.analyticsState.maximizedPagination;

    if (pagination === undefined) return null;
    if (unitID === undefined) return null;
    let targetStartTime = DateTime.fromISO(getDataParams.localStartTime, {
      zone: "utc",
    });

    let targetEndTime = DateTime.fromISO(getDataParams.localEndTime, {
      zone: "utc",
    });

    const currentTime = DateTime.fromISO(pagination.currentTime, {
      zone: "utc",
    });

    if (targetEndTime > currentTime) {
      targetEndTime = currentTime;
      targetStartTime = targetEndTime.minus({ hours: pagination.zoom });
    }

    let startTime = DateTime.fromISO(pagination.end, {
      zone: "utc",
    });

    let endTime = startTime.plus({
      days: CHART_PAGINATION_LOAD,
    });

    if (endTime > currentTime) {
      endTime = currentTime;
    }

    if (endTime.toISO() === startTime.toISO()) {
      return {
        localStart: targetStartTime.toISO(),
        localEnd: targetEndTime.toISO(),
        end: endTime.toISO(),
      };
    }

    switch (getDataParams.chartType) {
      case AnalyticsChartTypes.activity:
        startTime = startTime.minus({
          days: CHART_PAGINATION_LOAD,
        });
        const activityData = await dashboardServices.getUnitActivityData(
          unitID,
          startTime.toISO(),
          endTime.toISO()
        );

        return {
          localStart: targetStartTime.toISO(),
          localEnd: targetEndTime.toISO(),
          start: startTime.toISO(),
          end: endTime.toISO(),
          data: activityData,
        };
      case AnalyticsChartTypes.temperature:
        const temperatureData = await analyticsServices.getUnitTemperatureData(
          unitID,
          startTime.toISO(),
          endTime.toISO()
        );

        return {
          localStart: targetStartTime.toISO(),
          localEnd: targetEndTime.toISO(),
          end: endTime.toISO(),
          data: temperatureData,
        };
      default:
        return null;
    }
  }
);

export const getChartDataUp = createAsyncThunk(
  "analytics/getDataChartUp",
  async (getDataParams: GetChartData, { getState }) => {
    const appState = getState() as IAppState;
    const unitID = appState.analyticsState.selectedUnit;
    const pagination = appState.analyticsState.maximizedPagination;

    const isSleepChart = getDataParams.chartType === AnalyticsChartTypes.sleep;

    if (pagination === undefined) return null;
    if (unitID === undefined) return null;
    let targetStartTime = DateTime.fromISO(getDataParams.localStartTime, {
      zone: "utc",
    });

    let targetEndTime = DateTime.fromISO(getDataParams.localEndTime, {
      zone: "utc",
    });

    const currentTime = DateTime.fromISO(pagination.currentTime, {
      zone: "utc",
    });

    if (targetEndTime > currentTime) {
      targetEndTime = currentTime;
      // Sleep chart starts the day before to complete 14 days
      targetStartTime = targetEndTime
        .minus({ weeks: pagination.zoom })
        .plus({ day: !isSleepChart ? 1 : 0 });
    }

    const startTime = DateTime.fromISO(pagination.end, {
      zone: "utc",
    });

    let endTime = startTime.plus({
      weeks: chartWeekWindow,
    });

    if (endTime > currentTime) {
      endTime = currentTime;
    }

    if (endTime.toISO() === startTime.toISO()) {
      return {
        localStart: targetStartTime.toISODate(),
        localEnd: targetEndTime.toISODate(),
        end: endTime.toISODate(),
      };
    }

    switch (getDataParams.chartType) {
      case AnalyticsChartTypes.sleep:
        const sleepData = await analyticsServices.getUnitSleepData(unitID, {
          startDate: startTime.toISODate(),
          weeks: chartWeekWindow,
        });
        return {
          localStart: targetStartTime.toISODate(),
          localEnd: targetEndTime.toISODate(),
          end: endTime.toISODate(),
          data: sleepData,
        };
      case AnalyticsChartTypes.bathroom:
        const bathroomData = await analyticsServices.getUnitBathroomData(
          unitID,
          {
            startDate: startTime.toISODate(),
            weeks: chartWeekWindow,
          }
        );
        // Server sends requested weeks, even if they are after current date
        const filteredBathroomData = bathroomData.map((bathroomItem) => {
          const filteredData = bathroomItem.data.filter((dataItem) => {
            const date = DateTime.fromISO(dataItem.date, {
              zone: "utc",
            });
            return date <= currentTime;
          });
          return {
            ...bathroomItem,
            data: filteredData,
          };
        });
        return {
          localStart: targetStartTime.toISODate(),
          localEnd: targetEndTime.toISODate(),
          end: endTime.toISODate(),
          data: filteredBathroomData,
        };
      case AnalyticsChartTypes.kitchen:
        const kitchenData = await analyticsServices.getUnitKitchenActivityData(
          unitID,
          {
            startDate: startTime.toISODate(),
            weeks: chartWeekWindow,
          }
        );
        // Server sends requested weeks, even if they are after current date
        if (kitchenData.weekly_count) {
          kitchenData.weekly_count = kitchenData.weekly_count.filter(
            (dataItem) => {
              const date = DateTime.fromISO(dataItem.date, {
                zone: "utc",
              });
              return date <= currentTime;
            }
          );
        }

        return {
          localStart: targetStartTime.toISODate(),
          localEnd: targetEndTime.toISODate(),
          end: endTime.toISODate(),
          data: kitchenData,
        };
      case AnalyticsChartTypes.notification:
        const notificationData = await analyticsServices.getUnitNotificationData(
          unitID,
          {
            startDate: startTime.toISODate(),
            weeks: chartWeekWindow,
          }
        );
        return {
          localStart: targetStartTime.toISODate(),
          localEnd: targetEndTime.toISODate(),
          end: endTime.toISODate(),
          data: notificationData,
        };
      default:
        return null;
    }
  }
);

export const getChartDataLeft = createAsyncThunk(
  "analytics/getChartDataLeft",
  async (getDataParams: GetChartData, { getState }) => {
    const appState = getState() as IAppState;
    const unitID = appState.analyticsState.selectedUnit;
    const pagination = appState.analyticsState.maximizedPagination;

    if (pagination === undefined) return null;
    if (unitID === undefined) return null;
    const endTime = DateTime.fromISO(pagination.start, {
      zone: "utc",
    });

    const startTime = endTime.minus({
      days: CHART_PAGINATION_LOAD,
    });

    switch (getDataParams.chartType) {
      case AnalyticsChartTypes.activity:
        const activityStart = endTime.minus({
          days: CHART_PAGINATION_LOAD / 2,
        });
        const activityEnd = endTime.plus({
          days: CHART_PAGINATION_LOAD / 2,
        });
        const activityData = await dashboardServices.getUnitActivityData(
          unitID,
          activityStart.toISO(),
          activityEnd.toISO()
        );

        return {
          newStartTime: activityStart.toISO(),
          newEndTime: activityEnd.toISO(),
          data: activityData,
        };
      case AnalyticsChartTypes.temperature:
        const temperatureData = await analyticsServices.getUnitTemperatureData(
          unitID,
          startTime.toISO(),
          endTime.toISO()
        );

        return {
          newStartTime: startTime.toISO(),
          data: temperatureData,
        };
      default:
        return null;
    }
  }
);

export const getChartDataDown = createAsyncThunk(
  "analytics/getChartDataDown",
  async (getDataParams: GetChartData, { getState }) => {
    const appState = getState() as IAppState;
    const unitID = appState.analyticsState.selectedUnit;
    const pagination = appState.analyticsState.maximizedPagination;

    if (pagination === undefined) return null;
    if (unitID === undefined) return null;
    const startTime = DateTime.fromISO(pagination.start, {
      zone: "utc",
    });

    switch (getDataParams.chartType) {
      case AnalyticsChartTypes.sleep:
        const sleepStartTime = startTime.minus({ weeks: chartWeekWindow });
        const sleepData = await analyticsServices.getUnitSleepData(unitID, {
          startDate: sleepStartTime.toISODate(),
          weeks: chartWeekWindow,
        });
        return {
          data: sleepData,
          newStartTime: sleepStartTime.toISODate(),
        };
      case AnalyticsChartTypes.bathroom:
        const bathroomStartTime = startTime
          .minus({ weeks: chartWeekWindow })
          .minus({ day: 1 });
        const bathroomData = await analyticsServices.getUnitBathroomData(
          unitID,
          {
            startDate: bathroomStartTime.toISODate(),
            weeks: chartWeekWindow,
          }
        );
        return {
          data: bathroomData,
          newStartTime: bathroomStartTime.toISODate(),
        };
      case AnalyticsChartTypes.kitchen:
        const kitchenStartTime = startTime
          .minus({ weeks: chartWeekWindow })
          .minus({ day: 1 });
        const kitchenData = await analyticsServices.getUnitKitchenActivityData(
          unitID,
          {
            startDate: kitchenStartTime.toISODate(),
            weeks: chartWeekWindow,
          }
        );
        return {
          data: kitchenData,
          newStartTime: kitchenStartTime.toISODate(),
        };
      case AnalyticsChartTypes.notification:
        const notificationStartTime = startTime.minus({
          weeks: chartWeekWindow,
        });
        const notificationData = await analyticsServices.getUnitNotificationData(
          unitID,
          {
            startDate: notificationStartTime.toISODate(),
            weeks: chartWeekWindow,
          }
        );
        return {
          data: notificationData,
          newStartTime: notificationStartTime.toISODate(),
        };
      default:
        return null;
    }
  }
);

// Get the chart information data for an unit
export const getUnitChartData = createAsyncThunk(
  "analytics/getUnitChartData",
  async (unit_id: string) => {
    const timeNow = DateTime.now().toUTC();
    const endTime = timeNow.toISO();
    const startTime = timeNow.minus({ hours: 72 }).toISO();

    const [
      sleep,
      bathroom,
      activity,
      temperature,
      kitchen,
      notification,
    ] = await Promise.all([
      analyticsServices.getUnitSleepData(unit_id),
      analyticsServices.getUnitBathroomData(unit_id),
      dashboardServices.getUnitActivityData(unit_id, startTime, endTime),
      analyticsServices.getUnitTemperatureData(unit_id, startTime, endTime),
      analyticsServices.getUnitKitchenActivityData(unit_id),
      analyticsServices.getUnitNotificationData(unit_id),
    ]);
    return { sleep, bathroom, activity, temperature, kitchen, notification };
  }
);

const getPaginationValues = (
  selectedDate: DateTime,
  startDate: DateTime,
  currentTime: DateTime,
  chartType?: string
) => {
  let targetStartTime = selectedDate.minus({ days: 7 });

  let targetEndTime = selectedDate.plus({ days: 6 });

  if (targetEndTime > currentTime) {
    targetEndTime = currentTime;
    targetStartTime = targetEndTime.minus({ weeks: 2 });
  }

  let endTime = startDate.plus({ weeks: 8 });

  if (endTime > currentTime) {
    endTime = currentTime;
  }
  if (chartType === AnalyticsChartTypes.sleep) {
    targetEndTime = targetEndTime.plus({ day: 1 });
  }

  return {
    localStart: targetStartTime.toISODate(),
    localEnd: targetEndTime.toISODate(),
    start: startDate.toISODate(),
    end: endTime.toISODate(),
  };
};

// Gets information to expand the analytics chart
export const getChartDataByDate = createAsyncThunk(
  "analytics/getDataByDate",
  async (
    getDataParams: {
      chartType: string;
      localStartTime: string;
      localEndTime: string;
    },
    { getState }
  ) => {
    const appState = getState() as IAppState;
    const unitID = appState.analyticsState.selectedUnit;
    const pagination = appState.analyticsState.maximizedPagination;
    if (pagination === undefined) return null;
    if (unitID === undefined) return null;
    let targetStartTime = DateTime.fromISO(getDataParams.localStartTime, {
      zone: "utc",
    });

    let targetEndTime = DateTime.fromISO(getDataParams.localEndTime, {
      zone: "utc",
    });

    const currentTime = DateTime.fromISO(pagination.currentTime, {
      zone: "utc",
    });

    if (targetEndTime > currentTime) {
      targetEndTime = currentTime;
      targetStartTime = targetEndTime.minus({ hours: pagination.zoom });
    }

    let parsedDate = targetStartTime.plus({ hours: pagination.zoom / 2 });

    const startTime = parsedDate.minus({
      day: CHART_PAGINATION_LOAD / 2,
    });

    let endTime = parsedDate.plus({ day: CHART_PAGINATION_LOAD / 2 });

    if (endTime > currentTime) {
      endTime = currentTime;
    }

    switch (getDataParams.chartType) {
      case AnalyticsChartTypes.activity:
        const activityData = await dashboardServices.getUnitActivityData(
          unitID,
          startTime.toISO(),
          endTime.toISO()
        );

        return {
          localStart: targetStartTime.toISO(),
          localEnd: targetEndTime.toISO(),
          start: startTime.toISO(),
          end: endTime.toISO(),
          data: activityData,
        };

      case AnalyticsChartTypes.temperature:
        const temperatureData = await analyticsServices.getUnitTemperatureData(
          unitID,
          startTime.toISO(),
          endTime.toISO()
        );

        return {
          localStart: targetStartTime.toISO(),
          localEnd: targetEndTime.toISO(),
          start: startTime.toISO(),
          end: endTime.toISO(),
          data: temperatureData,
        };
      case AnalyticsChartTypes.sleep:
        const getSleepData = async (selectedDate: DateTime) => {
          const startDate = selectedDate.minus({ weeks: 4 });
          const sleepData = await analyticsServices.getUnitSleepData(unitID, {
            startDate: startDate.toISODate(),
            weeks: 8,
          });

          const paginationState = getPaginationValues(
            selectedDate,
            startDate,
            currentTime,
            AnalyticsChartTypes.sleep
          );

          return {
            ...paginationState,
            data: sleepData,
          };
        };
        const sleepData = await getSleepData(targetStartTime);

        return sleepData;
      case AnalyticsChartTypes.bathroom:
        const getBathroomData = async (selectedDate: DateTime) => {
          const startDate = selectedDate.minus({ weeks: 4 });
          const sleepData = await analyticsServices.getUnitBathroomData(
            unitID,
            {
              startDate: startDate.toISODate(),
              weeks: 8,
            }
          );

          const paginationState = getPaginationValues(
            selectedDate,
            startDate,
            currentTime
          );

          return {
            ...paginationState,
            data: sleepData,
          };
        };
        const bathroomData = await getBathroomData(targetStartTime);

        return bathroomData;
      case AnalyticsChartTypes.kitchen:
        const getKitchenData = async (selectedDate: DateTime) => {
          const startDate = selectedDate.minus({ weeks: 4 });
          const kitchenData = await analyticsServices.getUnitKitchenActivityData(
            unitID,
            {
              startDate: startDate.toISODate(),
              weeks: 8,
            }
          );

          const paginationState = getPaginationValues(
            selectedDate,
            startDate,
            currentTime
          );

          return {
            ...paginationState,
            data: kitchenData,
          };
        };
        const kitchenData = await getKitchenData(targetStartTime);

        return kitchenData;

      case AnalyticsChartTypes.notification:
        const getNotificationData = async (selectedDate: DateTime) => {
          const startDate = selectedDate.minus({ weeks: 4 });
          const notificationData = await analyticsServices.getUnitNotificationData(
            unitID,
            {
              startDate: startDate.toISODate(),
              weeks: 8,
            }
          );
          const paginationState = getPaginationValues(
            selectedDate,
            startDate,
            currentTime
          );
          return {
            ...paginationState,
            data: notificationData,
          };
        };
        const notificationData = await getNotificationData(targetStartTime);
        return notificationData;
      default:
        return null;
    }
  }
);
